/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import moment from "moment";
import MainContainer from "../../layout/MainContainer";
import {
  Card,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Button,
  TableBody,
  Select,
  MenuItem,
  FormControl,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import SearchBar from "../../components/SearchBar";
import { Transaction } from "../../types/General";
import { Loader, showError, showToast } from "../../constants";
import { Pagination } from "../../components";
import { isValidInput } from "../../utils/validations";
import { GetTransactions, UpdateArticleStatus } from "../../services/home";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

const Transactions = () => {
  const [allTransactions, setAllTransactions] = useState<Transaction[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [opened, setOpened] = useState<boolean>(false);
  const [role, setRole] = useState<string>("");
  const [startDate, setStartDate] = useState<any | null>(null);
  const [endDate, setEndDate] = useState<any | null>(null);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>("");
  const [totalCount, setTotalCount] = useState<number>(0);
  const [page, setPage] = useState(1);
  let totalPages = Math.ceil(totalCount / 10);

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  const getArticles = async (
    level: string,
    startDate: string,
    endDate: string
  ) => {
    try {
      setLoading(true);
      const response = (await GetTransactions({
        limit: 10,
        skip: (page - 1) * 10,
        search: debouncedSearchTerm,
        platform: level,
        startDate,
        endDate,
      })) as {
        status: number;
        count: number;
        data: Transaction[];
      };
      if (response?.status === 200) {
        setLoading(false);
        setAllTransactions(response?.data || []);
        setTotalCount(response?.count || 10);
        setLoading(false);
      } else {
        setLoading(false);
        setAllTransactions([]);
      }
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      showError(error?.data?.message || "");
    }
  };

  const resetValues = () => {
    setRole("");
    setStartDate(null);
    setEndDate(null);
    setPage(1);
    getArticles("", "", "");
    setOpened(false);
  };

  const applyFilter = () => {
    getArticles(
      role,
      startDate ? dayjs(startDate).format("YYYY-MM-DD") : "",
      endDate ? dayjs(endDate).format("YYYY-MM-DD") : ""
    );
    setOpened(false);
  };

  const handleStatusChange = async (status: string, id: string) => {
    try {
      setLoading(true);

      const response = (await UpdateArticleStatus(id, { status })) as {
        status: number;
      };

      if (response?.status === 200) {
        setLoading(false);
        showToast("Status updated successfully");
        let articles = [...allTransactions];
        let idx = -1;
        idx = articles?.findIndex((item) => item?._id === id);
        if (idx > -1) {
          articles[idx] = {
            ...articles[idx],
            status,
          };
        }
        setAllTransactions([...articles]);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      showError("something went wrong");
      console.log(error);
    }
  };

  useEffect(() => {
    getArticles(role, startDate || "", endDate || "");
  }, [debouncedSearchTerm, page]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <MainContainer>
        <Loader isLoad={loading} />
        <div onClick={() => setOpened(false)} className="main_loyout">
          <div className="dashboard">
            <div className="heading-bread">
              <h1 className="mn_hdng">Transactions</h1>
            </div>
          </div>
          <Card className="cards">
            <Box className="cards_header">
              <Grid
                container
                spacing={2}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Grid item>
                  <SearchBar
                    placeholder="Search by User name"
                    searchTerm={searchTerm}
                    setDebouncedSearchTerm={setDebouncedSearchTerm}
                    value={searchTerm}
                    onCross={() => setSearchTerm("")}
                    onChange={(val: any) => {
                      if (isValidInput(val.target.value)) {
                        setPage(1);
                        setSearchTerm(val.target.value);
                      }
                    }}
                  />
                </Grid>
                <Grid item>
                  <div className="parent-box">
                    <Button
                      onClick={(e: any) => {
                        e.stopPropagation();
                        setOpened(true);
                      }}
                      id="basic-button"
                      variant="contained"
                    >
                      Filter
                    </Button>
                    {opened ? (
                      <div
                        onClick={(e) => e.stopPropagation()}
                        className="filter-options"
                      >
                        <h3>Filter Options</h3>
                        <div className="filter-div">
                          <FormControl fullWidth>
                            <label>Platform</label>
                            <select
                              onChange={(val) => setRole(val.target.value)}
                              value={role}
                              name="cars"
                              id="cars"
                            >
                              <option value="">Any</option>
                              <option value="Android">Android</option>
                              <option value="IOS">IOS</option>
                            </select>
                          </FormControl>
                          <FormControl fullWidth>
                            <label>Period</label>
                            <div
                              className="range-picker"
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                gap: "20px",
                              }}
                            >
                              <DatePicker
                                sx={{ height: "40px" }}
                                value={startDate}
                                onChange={(val) => {
                                  setStartDate(val);
                                }}
                                // disableFuture
                                format="DD/MM/YYYY"
                                label="From"
                              />
                              <DatePicker
                                sx={{ height: "40px" }}
                                value={endDate}
                                minDate={startDate}
                                onChange={(val) => {
                                  setEndDate(val);
                                }}
                                disableFuture
                                format="DD/MM/YYYY"
                                label="Till"
                              />
                            </div>
                          </FormControl>

                          <div className="button-div">
                            <Button onClick={resetValues} id="basic-button">
                              Reset
                            </Button>
                            <Button
                              onClick={applyFilter}
                              id="basic-button"
                              variant="contained"
                            >
                              Apply
                            </Button>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </Grid>
              </Grid>
            </Box>
            <TableContainer className="table_container">
              <Box className="heading"></Box>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">SUBSCRIBER</TableCell>
                    <TableCell align="center">UID</TableCell>
                    <TableCell align="center">PLATFORM</TableCell>
                    <TableCell align="center">AMOUNT</TableCell>
                    <TableCell align="center">START DATE</TableCell>
                    <TableCell align="center">EXPIRY</TableCell>
                    <TableCell align="center">STATUS</TableCell>
                    {/* <TableCell align="right">ACTIONS</TableCell> */}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {allTransactions?.length ? (
                    allTransactions?.map((item: any, i) => {
                      return (
                        <TableRow>
                          <TableCell align="left">
                            {item?.userData[0]?.username || "N/A"}
                          </TableCell>
                          <TableCell align="center">
                            {item?.uuid || "N/A"}
                          </TableCell>
                          <TableCell align="center">
                            {item?.paymentRespones?.store === "APP_STORE"
                              ? "IOS"
                              : "ANDROID" || "N/A"}
                          </TableCell>
                          <TableCell align="center">
                            {item?.amount ? `₹${item?.amount}` : "N/A"}
                          </TableCell>
                          <TableCell align="center">
                            {moment(item?.updatedAt).format(
                              "MMM DD, YYYY (HH:mm)"
                            )}
                          </TableCell>
                          <TableCell align="center">
                            {moment(
                              item?.paymentRespones?.expirationDate
                            ).format("MMM DD, YYYY (HH:mm)")}
                          </TableCell>
                          <TableCell align="center">
                            {!!item?.paymentRespones?.isActive
                              ? "Active"
                              : "Inactive"}
                          </TableCell>
                        </TableRow>
                      );
                    })
                  ) : (
                    <TableRow>
                      <TableCell
                        align="center"
                        colSpan={10}
                        sx={{ color: "#051140" }}
                      >
                        No Transaction Found
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Card>
          <Pagination
            module={allTransactions || []}
            page={page}
            onPageChange={onPageChange}
            totalPages={totalPages}
          />
        </div>
      </MainContainer>
    </LocalizationProvider>
  );
};

export default Transactions;
{
  /* <TableCell align="right"> */
}
{
  /* <Box className="table_actions">
                              <Select
                                className="select_div"
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={""}
                                style={{ width: "92px" }}
                                inputProps={{
                                  "aria-label": "Without label",
                                }}
                                displayEmpty */
}
// onChange={(val) => {
//   if (val.target.value !== "VIEW") {
//     updateStatus(val.target.value, item?._id);
//   }
// }}
// >
{
  /* <MenuItem
                                  className="action-item"
                                  style={{ display: "none" }}
                                  disabled
                                  value=""
                                >
                                  Actions{" "}
                                </MenuItem> */
}
{
  /* <MenuItem
                                className="action-item"
                                value={item?.isDeleted === 1 ? 2 : 1}
                              >
                                {item?.isDeleted === 1 ? "Disable" : "Enable"}
                              </MenuItem>
                              <MenuItem
                                className="action-item"
                                onClick={() =>
                                  navigate(`/comments/${item?._id}`, {
                                    state: { item },
                                  })
                                }
                                value="VIEW"
                              >
                                View
                              </MenuItem> */
}
// </Select>
// </Box>
// </TableCell>
