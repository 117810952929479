import { END_POINTS } from "../../constants/url";
import { post } from "../../utils/AxiosMethods"

export const LoginService = async (body: any) => {
    try {
        const response = await post(END_POINTS.login, body);
        return response;
    } catch (error) {
        console.log(error);
    }
}