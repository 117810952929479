import { useNavigate, useParams } from "react-router-dom";
import { Button, Card, Grid } from "@mui/material";
import MainContainer from "../../../layout/MainContainer";
import { useEffect, useState } from "react";
import { Comment } from "../../../types/General";
import { GetCommentById, UpdateCommentStatus } from "../../../services/home";
import { Loader, showError, showToast } from "../../../constants";

const CommentDetails = () => {
  const { commentId } = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);
  const [commentData, setCommentData] = useState<Comment | null>(null);
  const [message , setMessage] = useState<any>()
  const updateStatus = async () => {
    try {
      if (!commentId) {
        return;
      }
      setLoading(true);

      const response = (await UpdateCommentStatus(commentId, {
        isDeleted: commentData?.isDeleted === 1 ? 2 : 1,
        commentData:commentData
      })) as {
        status: number;
      };

      if (response?.status === 200) {
        setLoading(false);
        showToast("Status updated successfully");
        if (commentData) {
          setCommentData({
            ...commentData,
            isDeleted: commentData?.isDeleted === 1 ? 2 : 1,
          });
        }
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      showError("something went wrong");
      console.log(error);
    }
  };

  const getCommentDetails = async () => {
    if (!commentId) {
      return;
    }
    try {
      const response = (await GetCommentById({ commentId })) as {
        data: Comment;
        code: number;
      };
      if (response?.code === 200) {
        setCommentData(response?.data || null);
        setMessage( response?.data?.message.replace(
          /\[@[^\]]+\]/g,
          (match) => {
            // Extract only the name part without brackets and colon
            return match.slice(2, -1).split(":")[0];
          }
        ))
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCommentDetails();
  }, [commentId]);

  return (
    <MainContainer>
      <Loader isLoad={loading} />
      <div>
        <div className="main_loyout">
          <div className="dashboard">
            <div className="heading-bread">
              <h1 className="mn_hdng">View Comment</h1>
            </div>
            <Button
              style={{ background: "#0d0e12" }}
              className="btn btn_primary"
              onClick={() => navigate(-1)}
            >
              Go Back
            </Button>
          </div>

          <div style={{ display: "flex", gap: "30px", marginTop: "30px" }}>
            <Grid container lg={6} md={6} sm={6} xs={12}>
              <Card
                sx={{ width: "100%", minHeight: 180, position: "relative" }}
                className="cards"
              >
                <div>
                  <h4
                    style={{
                      margin: "0px 0px 6px",
                      fontSize: "16px",
                      fontWeight: "700",
                    }}
                  >
                    Comment
                  </h4>
                  <p
                    style={{
                      margin: "0px 0px 20px",
                      fontSize: "14px",
                      color: "rgb(120, 130, 157)",
                      fontWeight: "400",
                    }}
                  >
                    {message || ""}
                  </p>
                  <Button
                    style={{
                      background: "#0d0e12",
                      position: "absolute",
                      bottom: 10,
                      right: 10,
                      height: "35px",
                      padding: "5px 10px",
                    }}
                    className="btn btn_primary"
                    onClick={updateStatus}
                  >
                    {commentData?.isDeleted === 1 ? "Disable" : "Enable"}
                  </Button>
                </div>
              </Card>
            </Grid>
            <Grid container lg={6} md={6} sm={6} xs={12}>
              <Card style={{ width: "100%", minHeight: 180 }} className="cards">
                <div className="parent-border-box">
                  <h4
                    style={{
                      margin: "0px 0px 6px",
                      fontSize: "16px",
                      fontWeight: "700",
                    }}
                  >
                    Parent Comment
                  </h4>
                  <p
                    style={{
                      margin: "0px 0px 20px",
                      fontSize: "14px",
                      color: "rgb(120, 130, 157)",
                      fontWeight: "400",
                    }}
                  >
                    {commentData?.parentMessage || "N/A"}
                  </p>
                </div>
              </Card>
            </Grid>
          </div>
          <Grid container lg={12} md={12} sm={12} xs={12} mt={5}>
            <Card style={{ width: "100%", minHeight: 180 }} className="cards">
              <div className="parent-border-box">
                <h4
                  style={{
                    margin: "0px 0px 6px",
                    fontSize: "16px",
                    fontWeight: "700",
                  }}
                >
                  Post
                </h4>
                <p
                  style={{
                    margin: "0px 0px 20px",
                    fontSize: "14px",
                    color: "rgb(120, 130, 157)",
                    fontWeight: "500",
                  }}
                >
                  {commentData?.post?.title || ""}
                </p>
                <p
                  style={{
                    margin: "0px 0px 20px",
                    fontSize: "14px",
                    color: "rgb(120, 130, 157)",
                    fontWeight: "400",
                  }}
                >
                  {commentData?.post?.description || ""}
                </p>
              </div>
            </Card>
          </Grid>
        </div>
      </div>
    </MainContainer>
  );
};

export default CommentDetails;
