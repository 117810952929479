import React from "react";
import { useParams } from "react-router-dom";
import ManageGSTArticles from "./article";
import ManageGSTVideos from "./video";

const GST = () => {
  const { type } = useParams();

  return type && type === "articles" ? (
    <ManageGSTArticles />
  ) : (
    <ManageGSTVideos />
  );
};

export default GST;
