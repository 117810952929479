/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";

import FormControl from "@mui/material/FormControl";

import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Select,
  MenuItem,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Customers } from "../../types/General";
import { Loader, showError, showToast } from "../../constants";
import { Pagination, SearchBar } from "../../components";
import { isValidInput } from "../../utils/validations";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment";
import {
  GetUsers,
  UpdateUserLevel,
  UpdateUserStatus,
} from "../../services/home";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";

const ManageUsers = () => {
  const navigate = useNavigate();
  const [allUsers, setAllUsers] = useState<Customers[]>([]);
  const [visibleUsers, setVisibleUsers] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [opened, setOpened] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [role, setRole] = useState<string>("");
  const [startDate, setStartDate] = useState<string | null>(null);
  const [endDate, setEndDate] = useState<string | null>(null);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>("");
  const [totalCount, setTotalCount] = useState<number>(0);
  const [page, setPage] = useState(1);
  const [pointsSortStatus, setPointsSortStatus] = useState(0);
  const [lastActiveSortStatus, setLastActiveSortStatus] = useState(0);

  let totalPages = Math.ceil(totalCount / 10);

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  const showStatus = (status: string) => {
    if (status === "DELETED") {
      return "Disabled";
    } else if (status === "BLOCK") {
      return "Banned";
    } else {
      return "Active";
    }
  };

  const getUsers = async (
    level: string,
    startDate: string,
    endDate: string
  ) => {
    try {
      setLoading(true);
      const response = (await GetUsers({
        limit: 10,
        skip: page - 1,
        search: debouncedSearchTerm,
        level,
        startDate,
        endDate,
        lastActiveSortStatus,
      })) as {
        code: number;
        data: Customers[];
        count: number;
      };
      if (response?.code === 200) {
        setLoading(false);
        setAllUsers(response?.data || []);
        setVisibleUsers(response?.data || []);
        setTotalCount(response?.count || 0);
        setLoading(false);
      } else {
        setLoading(false);
        setAllUsers([]);
        setVisibleUsers([]);
      }
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      showError(error?.data?.message || "");
    }
  };

  const updateStatus = async (status: string, id: string) => {
    try {
      setLoading(true);

      const response = (await UpdateUserStatus(id, { status })) as {
        status: number;
      };

      if (response?.status === 200) {
        setLoading(false);
        showToast("Status updated successfully");
        let users = [...allUsers];
        let idx = -1;
        idx = users?.findIndex((item) => item?._id === id);
        if (idx > -1) {
          users[idx] = {
            ...users[idx],
            status,
          };
        }
        setVisibleUsers([...users]);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      showError("something went wrong");
      console.log(error);
    }
  };

  const updateLevel = async (level: string, id: string) => {
    try {
      setLoading(true);

      const response = (await UpdateUserLevel(id, { level })) as {
        status: number;
      };

      if (response?.status === 200) {
        setLoading(false);
        showToast("Status updated successfully");
        getUsers(
          role,
          startDate ? dayjs(startDate).format("YYYY-MM-DD") : "",
          endDate ? dayjs(endDate).format("YYYY-MM-DD") : ""
        );
        // let users = [...allUsers];
        // let idx = -1;
        // idx = users?.findIndex((item) => item?._id === id);
        // if (idx > -1) {
        //   users[idx] = {
        //     ...users[idx],
        //     level,
        //   };
        // }
        // setVisibleUsers([...users]);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      showError("something went wrong");
      console.log(error);
    }
  };

  const applyFilter = () => {
    getUsers(
      role,
      startDate ? dayjs(startDate).format("YYYY-MM-DD") : "",
      endDate ? dayjs(endDate).format("YYYY-MM-DD") : ""
    );
    setOpened(false);
  };

  const resetValues = () => {
    setRole("");
    setStartDate(null);
    setEndDate(null);
    setPage(1);
    getUsers("", "", "");
    setOpened(false);
  };

  const sortPoints = () => {
    if (pointsSortStatus === 0) {
      setVisibleUsers([...allUsers].sort((a, b) => a.likeCount - b.likeCount));
      setPointsSortStatus(1);
    }
    if (pointsSortStatus === 1) {
      setVisibleUsers([...allUsers].sort((a, b) => b.likeCount - a.likeCount));
      setPointsSortStatus(-1);
    }
    if (pointsSortStatus === -1) {
      setVisibleUsers([...allUsers]);
      setPointsSortStatus(0);
    }
  };

  const sortLastActive = () => {
    if (lastActiveSortStatus === 0) {
      setLastActiveSortStatus(1);
    }
    if (lastActiveSortStatus === 1) {
      setLastActiveSortStatus(-1);
    }
    if (lastActiveSortStatus === -1) {
      setLastActiveSortStatus(0);
    }
  };

  useEffect(() => {
    getUsers(
      role,
      startDate ? dayjs(startDate).format("YYYY-MM-DD") : "",
      endDate ? dayjs(endDate).format("YYYY-MM-DD") : ""
    );
  }, [debouncedSearchTerm, page, lastActiveSortStatus]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <MainContainer>
        <Loader isLoad={loading} />
        <div onClick={() => setOpened(false)} className="main_loyout">
          <div className="dashboard">
            <div className="heading-bread">
              <h1 className="mn_hdng">Manage Users</h1>

              <Breadcrumbs aria-label="breadcrumb">
                <Link underline="hover" color="inherit" href="/manage-users">
                  User management
                </Link>
                <Link underline="hover" color="inherit" href="/manage-users">
                  User List
                </Link>
              </Breadcrumbs>
            </div>
          </div>
          <Card className="cards">
            <Box className="cards_header">
              <Grid
                container
                spacing={2}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Grid item>
                  <SearchBar
                    placeholder="Search by Name or Email"
                    searchTerm={searchTerm}
                    setDebouncedSearchTerm={setDebouncedSearchTerm}
                    value={searchTerm}
                    onChange={(val: any) => {
                      if (isValidInput(val.target.value)) {
                        setPage(1);
                        setSearchTerm(val.target.value);
                      }
                    }}
                  />
                </Grid>
                <Grid item>
                  <div className="parent-box">
                    <Button
                      onClick={(e: any) => {
                        e.stopPropagation();
                        setOpened(true);
                      }}
                      id="basic-button"
                      variant="contained"
                    >
                      Filter
                    </Button>
                    {opened ? (
                      <div
                        onClick={(e) => e.stopPropagation()}
                        className="filter-options"
                      >
                        <h3>Filter Options</h3>
                        <div className="filter-div">
                          <FormControl fullWidth>
                            <label>Role</label>
                            <select
                              onChange={(val) => setRole(val.target.value)}
                              value={role}
                              name="cars"
                              id="cars"
                            >
                              <option value="EXPERT">Expert</option>
                              <option value="NORMAL">User</option>
                              <option value="">Any</option>
                            </select>
                          </FormControl>
                          <FormControl fullWidth>
                            <label>Just Points</label>
                            <div
                              className="range-picker"
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                gap: "20px",
                              }}
                            >
                              <DatePicker
                                sx={{ height: "40px" }}
                                value={startDate}
                                onChange={(val) => {
                                  setStartDate(val);
                                }}
                                disableFuture
                                format="DD/MM/YYYY"
                                label="From"
                              />
                              <DatePicker
                                sx={{ height: "40px" }}
                                value={endDate}
                                minDate={startDate}
                                onChange={(val) => {
                                  setEndDate(val);
                                }}
                                disableFuture
                                format="DD/MM/YYYY"
                                label="Till"
                              />
                            </div>
                          </FormControl>

                          <div className="button-div">
                            <Button onClick={resetValues} id="basic-button">
                              Reset
                            </Button>
                            <Button
                              onClick={applyFilter}
                              id="basic-button"
                              variant="contained"
                            >
                              Apply
                            </Button>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </Grid>
              </Grid>
            </Box>
            <TableContainer className="table_container">
              <Box className="heading"></Box>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    {/* <TableCell align="center">S.No</TableCell> */}
                    <TableCell>Name</TableCell>
                    <TableCell align="center">UID</TableCell>
                    <TableCell align="center">Created</TableCell>
                    <TableCell align="center">
                      {/* <div onClick={sortLastActive} className="sortingArrow"> */}

                      <div
                      // className="sortingArrow"
                      >
                        <div>Last Active</div>
                        {/* <div
                          style={{
                            visibility:
                              lastActiveSortStatus === 0 ? "hidden" : "visible",
                          }}
                        >
                          {lastActiveSortStatus === 1 ? (
                            <KeyboardArrowDownIcon />
                          ) : (
                            <KeyboardArrowUpIcon />
                          )}
                        </div> */}
                      </div>
                    </TableCell>
                    <TableCell align="center">
                      {/* <div onClick={sortPoints} 
                    // className="sortingArrow"
                    > */}

                      <div
                      // className="sortingArrow"
                      >
                        <div>Just Points</div>
                        {/* <div
                          style={{
                            visibility:
                              pointsSortStatus === 0 ? "hidden" : "visible",
                          }}
                        >
                          {pointsSortStatus === 1 ? (
                            <KeyboardArrowDownIcon />
                          ) : (
                            <KeyboardArrowUpIcon />
                          )}
                        </div> */}
                      </div>
                    </TableCell>
                    <TableCell align="center">Role</TableCell>
                    <TableCell align="center">Level</TableCell>
                    <TableCell align="center">Status</TableCell>
                    <TableCell align="center">Actions</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {visibleUsers?.length ? (
                    visibleUsers?.map((item, i) => {
                      return (
                        <TableRow>
                          <TableCell>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                maxWidth: 200,
                                overflow: "hidden",
                              }}
                            >
                              <div style={{ marginRight: 10 }}>
                                <figure className="user_img">
                                  <img
                                    src={
                                      item?.profileImage
                                        ? item?.profileImage
                                        : "/static/images/user_placeholder.png"
                                    }
                                    alt=""
                                  />
                                </figure>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: "5px",
                                }}
                              >
                                <div
                                  className="username"
                                  onClick={() => {
                                    navigate(`/manage-users/${item?._id}`, {
                                      state: { item },
                                    });
                                  }}
                                >
                                  {`${item?.username}` || ""}
                                </div>
                                <div
                                  style={{
                                    color: "#78829D",
                                    fontSize: 12,
                                  }}
                                >
                                  {item?.email || ""}
                                </div>
                              </div>
                            </div>
                          </TableCell>

                          <TableCell align="center">
                            {item?.uuid || ""}
                          </TableCell>
                          <TableCell align="center">
                            {moment(item?.createdAt || new Date()).format(
                              "MMM DD, YYYY"
                            )}
                          </TableCell>
                          <TableCell align="center">
                            {moment(item?.updatedAt || new Date()).fromNow()}
                          </TableCell>
                          <TableCell align="center">
                            {item?.commentLikeCount + item?.postLikeCount || 0}
                          </TableCell>
                          <TableCell align="center">
                            {item?.role == "USER" ? "user" : item?.role || ""}
                          </TableCell>
                          <TableCell
                            sx={{ textTransform: "lowercase" }}
                            align="center"
                          >
                            {item?.level || ""}
                          </TableCell>
                          <TableCell align="center">
                            <span
                              className={`status ${showStatus(item?.status || "")}`}
                            >
                              {showStatus(item?.status || "")}
                            </span>
                          </TableCell>
                          <TableCell align="right">
                            <Box className="table_actions">
                              <Select
                                className="select_div"
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={""}
                                style={{ width: "92px" }}
                                inputProps={{
                                  "aria-label": "Without label",
                                }}
                                displayEmpty
                                onChange={(val) => {
                                  console.log(val.target.value);

                                  if (val.target.value !== "VIEW") {
                                    if (
                                      val.target.value === "EXPERT" ||
                                      val.target.value === "NORMAL"
                                    ) {
                                      updateLevel(val.target.value, item?._id);
                                    } else {
                                      updateStatus(val.target.value, item?._id);
                                    }
                                  }
                                }}
                              >
                                <MenuItem
                                  className="action-item"
                                  style={{ display: "none" }}
                                  disabled
                                  value=""
                                >
                                  Actions{" "}
                                </MenuItem>
                                <MenuItem
                                  style={{
                                    display:
                                      item?.status === "ACTIVE" ||
                                      item?.status === "DELETED"
                                        ? "none"
                                        : "block",
                                  }}
                                  className="action-item"
                                  value="ACTIVE"
                                >
                                  Activate
                                </MenuItem>
                                <MenuItem
                                  style={{
                                    display:
                                      item?.status === "BLOCK" ||
                                      item?.status === "DELETED"
                                        ? "none"
                                        : "block",
                                  }}
                                  className="action-item"
                                  value="BLOCK"
                                >
                                  Ban
                                </MenuItem>
                                <MenuItem
                                  className="action-item"
                                  value={
                                    item?.level === "NORMAL"
                                      ? "EXPERT"
                                      : "NORMAL"
                                  }
                                >
                                  {`Make ${item?.level === "NORMAL" ? "Expert" : "Normal"}`}
                                </MenuItem>
                                <MenuItem
                                  className="action-item"
                                  onClick={() =>
                                    navigate(`/manage-users/${item?._id}`, {
                                      state: { item },
                                    })
                                  }
                                  value="VIEW"
                                >
                                  View
                                </MenuItem>
                              </Select>
                            </Box>
                          </TableCell>
                        </TableRow>
                      );
                    })
                  ) : (
                    <TableRow>
                      <TableCell
                        align="center"
                        colSpan={10}
                        sx={{ color: "#051140" }}
                      >
                        No Users Found
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Card>
          <Pagination
            module={allUsers}
            page={page}
            onPageChange={onPageChange}
            totalPages={totalPages}
          />
        </div>
      </MainContainer>
    </LocalizationProvider>
  );
};

export default ManageUsers;
