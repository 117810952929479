import { END_POINTS } from "../../constants/url";
import { del, formDataPost, formDataPut, get, post, put } from "../../utils/AxiosMethods"

export const GetDashboard = async ({ filterBy }: { filterBy: string; }) => {
    try {
        const response = await get(END_POINTS.dashboard, { filterBy });
        return response;
    } catch (error) {
        console.log(error);
    }
}

export const GetUsers = async ({ limit, skip, search, level, startDate, endDate, lastActiveSortStatus }: { limit: number; skip: number; search: string; level: string, startDate: string, endDate: string; lastActiveSortStatus: number }) => {
    try {
        const response = await get(END_POINTS.user, { limit, skip, search, level, startDate, endDate, lastActiveSortStatus });
        return response;
    } catch (error) {
        console.log(error);
    }
}

export const GetUser = async (id: string) => {
    try {
        const response = await get(`${END_POINTS.user}/${id}`);
        return response;
    } catch (error) {
        console.log(error);
    }
}

export const UpdateUserStatus = async (id: string, body: { status: string }) => {
    try {
        const response = await put(`${END_POINTS.updateUser}/${id}`, body);
        return response;
    } catch (error) {
        console.log(error);
    }
}

export const UpdateUserLevel = async (id: string, body: { level: string }) => {
    try {
        const response = await put(`${END_POINTS.updateUser}/${id}`, body);
        return response;
    } catch (error) {
        console.log(error);
    }
}

export const GetDiscussions = async ({ limit, skip, search, startDate, endDate, sortBy }: { limit: number; skip: number; search: string; startDate?: string, endDate?: string; sortBy: string }) => {
    try {
        const response = await get(END_POINTS.discussion, { limit, skip, search, startDate, endDate, sortBy });
        return response;
    } catch (error) {
        console.log(error);
    }
}

export const GetDiscussionById = async ({ articleId }: { articleId: string }) => {
    try {
        const response = await get(`${END_POINTS.discussion}/${articleId}`);
        return response;
    } catch (error) {
        console.log(error);
    }
}

export const GetComments = async ({ limit, skip, search, startDate, endDate, likes }: { limit: number; skip: number; search: string; likes: string; startDate?: string, endDate?: string }) => {
    try {
        const response = await get(END_POINTS.comment, { limit, skip, search, likes, startDate, endDate });
        return response;
    } catch (error) {
        console.log(error);
    }
}

export const GetCommentById = async ({ commentId }: { commentId: string }) => {
    try {
        const response = await get(`${END_POINTS.comment}/${commentId}`);
        return response;
    } catch (error) {
        console.log(error);
    }
}

export const UpdateCommentStatus = async (id: string, body: any) => {
    try {
        const response = await put(`${END_POINTS.updateComment}/${id}`, body);
        return response;
    } catch (error) {
        console.log(error);
    }
}

export const GetArticles = async ({ limit, skip, search, postType, startDate, endDate, status, categoriesId }: { limit: number; skip: number; search: string; status: string; postType: string; startDate?: string, endDate?: string; categoriesId?: string }) => {
    try {
        const response = await get(END_POINTS.article, { limit, skip, search, postType, status, categoriesId, startDate, endDate });
        return response;
    } catch (error) {
        console.log(error);
    }
}

export const GetArticleById = async ({ articleId }: { articleId: string }) => {
    try {
        const response = await get(`${END_POINTS.article}/${articleId}`);
        return response;
    } catch (error) {
        console.log(error);
    }
}

export const UpdateArticleStatus = async (id: string, body: { status: string }) => {
    try {
        const response = await put(`${END_POINTS.updateArticle}/${id}`, body);
        return response;
    } catch (error) {
        console.log(error);
    }
}
export const UpdatePostStatus = async (id: string, body: { status: string }) => {
    try {
        const response = await put(`${END_POINTS.postUpdate}/${id}`, body);
        return response;
    } catch (error) {
        console.log(error);
    }
}
export const GetCategories = async () => {
    try {
        const response = await get(END_POINTS.categories);
        return response;
    } catch (error) {
        console.log(error);
    }
}

export const GetTransactions = async ({ limit, search, skip, platform, startDate, endDate }: { limit: number; skip: number; search: string, platform: string, startDate?: string, endDate?: string; }) => {
    try {
        const response = await get(`${END_POINTS.subscription}?status=""&limit=${limit}&skip=${skip}&search=${search}&platform=${platform}&startDate=${startDate}&endDate=${endDate}`);
        return response;
    } catch (error) {
        console.log(error);
    }
}

export const AddArticle = async (body: FormData) => {
    try {
        const response = await formDataPost(END_POINTS.addArticle, body);
        return response;
    } catch (error) {
        console.log(error);
    }
}

export const UpdateArticle = async (body: FormData, articleId: string) => {
    try {
        const response = await formDataPut(`${END_POINTS.addArticle}/${articleId}`, body);
        return response;
    } catch (error) {
        console.log(error);
    }
}

export const ArticleLikes = async (articleId: string) => {
    try {
        const response = await get(`${END_POINTS.articleLike}/${articleId}`);
        return response;
    } catch (error) {
        console.log(error);
    }
}

export const DeleteArticle = async (articleId: string) => {
    try {
        const response = await del(`${END_POINTS.deleteArticle}/${articleId}`);
        return response;
    } catch (error) {
        console.log(error);
    }
}

export const AddFcm = async (body: any) => {
    try {
        const response = await post(END_POINTS.addFcm, body);
        return response;
    } catch (error) {
        console.log(error);
    }
}

export const GetNotificationCount = async () => {
    try {
        const response = await get(`${END_POINTS.notificationCount}`);
        return response;
    } catch (error) {
        console.log(error);
    }
}

export const GetNotifications = async ({limit,skip} : {limit: number; skip: number}) => {
    try {
        const response = await get(`${END_POINTS.notification}` , {limit, skip});
        return response;
    } catch (error) {
        console.log(error);
    }
}

export const UpdateNotification = async (body: { notificationId: string, isRead: boolean }) => {
    try {
        const response = await put(`${END_POINTS.notification}`, body);
        return response;
    } catch (error) {
        console.log(error);
    }
}

export const GetSubscriptionById = async (userId:any ) => {
    try {
        const response = await get(`${END_POINTS.subscription}/${userId}`);
        return response;
    } catch (error) {
        console.log(error);
    }
}