import { PropsWithoutRef, useState } from "react";
import {
  Drawer,
  Toolbar,
  List,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  Box,
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import SummarizeTwoToneIcon from "@mui/icons-material/SummarizeTwoTone";
import CloseIcon from "@mui/icons-material/Close";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PeopleIcon from "@mui/icons-material/People";
import { SIDEBAR_WIDTH } from "../constants";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import PaymentsIcon from "@mui/icons-material/Payments";
import ModeCommentIcon from "@mui/icons-material/ModeComment";
import ReportIcon from "@mui/icons-material/Report";
import BabyChangingStationIcon from "@mui/icons-material/BabyChangingStation";
import FaceRetouchingNaturalIcon from "@mui/icons-material/FaceRetouchingNatural";
import ReceiptIcon from "@mui/icons-material/Receipt";
import ForumIcon from '@mui/icons-material/Forum';
import FlightLandIcon from "@mui/icons-material/FlightLand";
interface Props {
  mobileOpen: boolean;
  handleSidebarToggle: () => void;
}

export default function Sidebar({
  mobileOpen,
  handleSidebarToggle,
}: PropsWithoutRef<Props>) {
  const location = useLocation();
  const navigate = useNavigate();

  const [expanded, setExpanded] = useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const items = [
    {
      label: "Dashboard",
      icon: <DashboardIcon />,
      key: "1",
      selected: "/dashboard",
    },
    {
      label: "Users",
      icon: <PeopleIcon />,
      key: "2",
      selected: "/manage-users",
    },
    {
      label: "Transactions",
      icon: <PaymentsIcon />,
      key: "3",
      selected: "/transactions",
    },
    {
      label: "Comments ",
      icon: <ModeCommentIcon />,
      key: "4",
      selected: "/comments",
    },
    {
      label: "Reported ",
      icon: <ReportIcon />,
      key: "4",
      selected: "/reported",
    },
  ];

  const Others = [
    {
      name: "GST",
      icon: <ReceiptIcon />,
      pages: [
        {
          name: "Articles",
          selected: "/manage-gst/articles",
        },
        {
          name: "Videos",
          selected: "/manage-gst/video",
        },
      ],
    },
    {
      name: "Customs",
      icon: <FlightLandIcon />,
      pages: [
        {
          name: "Articles",
          selected: "/manage-customs/articles",
        },
        {
          name: "Videos",
          selected: "/manage-customs/video",
        },
      ],
    },
  ];

  return (
    <Box
      component="nav"
      sx={{ flexShrink: { lg: 0 } }}
      className="sidebar-main-div"
    >
      <Drawer
        className="sidebar_drawer"
        variant="temporary"
        open={mobileOpen}
        onClose={handleSidebarToggle}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: { xs: "block", lg: "none" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: SIDEBAR_WIDTH,
          },
        }}
      >
        <Toolbar
          className="sidebar-logo"
          sx={{ position: "relative", justifyContent: "center" }}
        >
          <figure>
            <img
              src="/static/images/logo.png"
              alt=""
              onClick={() => navigate("/dashboard")}
            />
          </figure>
          <IconButton
            sx={{
              position: "absolute",
              top: "10px",
              right: "10px",
              color: "white",
              display: { lg: "none" },
            }}
            onClick={handleSidebarToggle}
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
        {items.map((item: any) => {
          const isSelected = location.pathname.includes(item?.selected);
          return (
            <List key={item?.label} className="sidebr-lst" sx={{ padding: 0 }}>
              <ListItemButton
                className={`lst-itm ${isSelected ? "Mui-selected" : ""}`}
                onClick={() => navigate(item?.selected)}
              >
                <ListItemIcon>{item?.icon}</ListItemIcon>
                <ListItemText className="lstitm-txt" primary={item?.label} />
              </ListItemButton>
            </List>
          );
        })}
        <div className="Accordion-Resources">
          <p className="menu-heading">Resources</p>
          {Others.map((item: any, index: number) => (
            <Accordion
              className="sidebr-lst"
              style={{ background: "black" }}
              expanded={
                expanded === item.name.toLowerCase() ||
                location?.pathname?.includes(item?.name?.toLowerCase())
              }
              onChange={handleChange(item.name.toLowerCase())}
              key={index}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
                className="custom-acc lstitm-txt"
              >
                <span className="cust-menu-icon">{item?.icon}</span> {item.name}
              </AccordionSummary>
              {item.pages.map((page: any, index1: any) => {
                const isSelected = location.pathname.includes(page.selected);
                return (
                  <ListItemButton
                    className={`lst-itm ${isSelected ? "Mui-selected" : ""}`}
                    onClick={() => navigate(page?.selected)}
                  >
                    <AccordionDetails
                      className="acc-details lstitm-txt"
                      key={index1}
                    >
                      <span>{page.name}</span>
                    </AccordionDetails>
                  </ListItemButton>
                );
              })}
            </Accordion>
          ))}
          <p className="menu-heading">Discussion</p>
          <List className="sidebr-lst" sx={{ padding: 0 }}>
            <ListItemButton
              className={`lst-itm ${location?.pathname?.includes("forum-posts") ? "Mui-selected" : ""}`}
              onClick={() => navigate("/forum-posts")}
            >
              {/* <ListItemIcon>{item?.icon}</ListItemIcon> */}
              <ListItemText className="lstitm-txt" primary={"Forum Posts"} />
            </ListItemButton>
          </List>
        </div>
      </Drawer>
      <Drawer
        variant="permanent"
        className="sidebar_drawer"
        sx={{
          display: { xs: "none", lg: "block" },
          "& .MuiDrawer-paper": {
            width: SIDEBAR_WIDTH,
            boxSizing: "border-box",
          },
        }}
        open
      >
        <Toolbar
          className="sidebar-logo"
          sx={{ position: "relative", justifyContent: "center" }}
        >
          <figure>
            <img
              src="/static/images/logo.png"
              alt=""
              onClick={() => navigate("/dashboard")}
            />
          </figure>
          <IconButton
            sx={{
              position: "absolute",
              top: "10px",
              right: "10px",
              color: "white",
              display: { lg: "none" },
            }}
            onClick={handleSidebarToggle}
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
        {items.map((item: any) => {
          const isSelected = location.pathname.includes(item?.selected);
          return (
            <Link key={item?.label} to={item?.selected} style={{textDecoration:"none"}}>
            <List  className="sidebr-lst" sx={{ padding: 0 }}>
              <ListItemButton
                className={`lst-itm ${isSelected ? "Mui-selected" : ""}`}
                // onClick={() => navigate(item?.selected)}
              >
                <ListItemIcon>{item?.icon}</ListItemIcon>
                <ListItemText className="lstitm-txt" primary={item?.label} />
              </ListItemButton>
            </List>
            </Link>
          );
        })}
        <p className="menu-heading">Resources</p>
        {Others.map((item: any, index: number) => (
          <Accordion
            expanded={
              expanded === item.name.toLowerCase() ||
              location?.pathname?.includes(item?.name?.toLowerCase())
            }
            onChange={handleChange(item.name.toLowerCase())}
            key={index}
            className="sidebr-lst"
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
              className="custom-acc lstitm-txt"
            >
              <span className="cust-menu-icon lstitm-txt">{item?.icon}</span>{" "}
              <span>{item.name}</span>
            </AccordionSummary>
            {item.pages.map((page: any, index1: any) => {
              const isSelected = location.pathname.includes(page.selected);
              return (

                <Link key={page?.name} to={page?.selected} style={{textDecoration:"none"}}>

                <ListItemButton
                  className={`lst-itm ${isSelected ? "Mui-selected" : ""}`}
                  onClick={() => navigate(page?.selected)}
                >
                  <AccordionDetails className="acc-details" key={index1}>
                    {page.name}
                  </AccordionDetails>
                </ListItemButton>
                </Link>
              );
            })}
          </Accordion>
        ))}
        <p className="menu-heading">Discussion</p>
        <Link to="/forum-posts" style={{textDecoration:"none"}}>

        <List className="sidebr-lst" sx={{ padding: 0 }}>
          <ListItemButton
            className={`lst-itm ${location?.pathname?.includes("forum-posts") ? "Mui-selected" : ""}`}
            // onClick={() => navigate("/forum-posts")}
          >
            <ListItemIcon><ForumIcon/></ListItemIcon>
            <ListItemText className="lstitm-txt" primary={"Forum Posts"} />
          </ListItemButton>
        </List>
        </Link>
      </Drawer>
    </Box>
  );
}
