
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { AddFcm } from "../services/home";

const firebaseConfig = {
  apiKey: "AIzaSyBqI8Yl5APRTLQ2JL1x7-p-gFCxK7iBef4",
  authDomain: "justidt.firebaseapp.com",
  projectId: "justidt",
  storageBucket: "justidt.appspot.com",
  messagingSenderId: "816026856027",
  appId: "1:816026856027:web:e85a823bea1cfa28b9ad7e",
  measurementId: "G-LDZYS9MWZK"
};

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

export const requestPermission = async (): Promise<void> => {
  try {
    console.log("Requesting permission for notifications...");
    const permission: NotificationPermission = await Notification.requestPermission();
    console.log(permission, "permission")
    if (permission === "granted") {
      console.log("Permission granted");
      const token: string | undefined = await getToken(messaging, {
        vapidKey: "BGcL37QU9MZeNKzL6DdOpMwL57VCB-_kuM5NFzo8F8WjL55XKfxTlwNrKItEt4XU0dD-D_E_xfB-b-Coqi-nHDo"
      });
      if (token) {
        const payload = {
          fcmToken:token
        }
        await AddFcm(payload)
        console.log("Token:", token);
        localStorage.setItem("fcmToken", token)
      } else {
        console.error("Unable to retrieve token");
      }
    } else {
      console.log("Permission denied");
    }
  } catch (error) {
    console.error("Error:", error);
  }
};


export const getMessage = (): void => {
  onMessage(messaging, (payload: any) => {
    console.log('Message received. ', payload);

    const notificationTitle: string | undefined = payload.notification?.title;
    const notificationBody: string | undefined = payload.notification?.body;

    const notificationOptions = {
      body: notificationBody,
      icon: "/favicon.ico"
    };

    // Display notification
    if (notificationTitle) {
      const notification = new Notification(notificationTitle, notificationOptions);
      
      // // Add click event listener to the notification
      // console.log(clickAction,"clickAction")
      // // if (clickAction) {
      //   notification.onclick = () => {
      //     window.open("http://localhost:3000//manage-customs/articles", '_blank'); // Open the click action URL in a new tab
      //   };
      // }
    } else {
      console.error("Notification title is undefined");
    }
  });
};

// export const getMessage = (): void => {
//   onMessage(messaging, (payload:any) => {
//     console.log('Message received. ', payload);

//     const notificationTitle: string | undefined = payload.notification?.title;
//     const notificationOptions = {
//       body: payload.notification?.body,
//       icon: "/favicon.ico",
//       click_action:payload.data.notification.click_action

//     };

//     // Display notification
//     if (notificationTitle) {
//       new Notification(notificationTitle, notificationOptions);
//     } else {
//       console.error("Notification title is undefined");
//     }
//   });
// };

