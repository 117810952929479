import React, { PropsWithoutRef, useEffect, useState } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Box,
  Menu,
  MenuItem,
  Typography,
  Button,
  Modal,
} from "@mui/material";
import LockResetIcon from "@mui/icons-material/LockReset";
import PersonIcon from "@mui/icons-material/Person";
import LogoutIcon from "@mui/icons-material/Logout";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import MenuIcon from "@mui/icons-material/Menu";
import { useNavigate } from "react-router-dom";
import {
  SIDEBAR_WIDTH,
  STORAGE_KEYS,
  getFromStorage,
  removeFromStorage,
} from "../constants";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import "./Layout.scss";
import { generateResponsiveStyle } from "../utils/ModalStyle";
import {
  GetNotificationCount,
  GetNotifications,
  UpdateNotification,
} from "../services/home";
import { Notification } from "../types/General";
import moment from "moment";

interface Props {
  handleSidebarToggle: () => void;
}

function Topbar({ handleSidebarToggle }: PropsWithoutRef<Props>) {
  const navigate = useNavigate();

  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [notificationCount, setNotificationCount] = useState(0);
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [anchorEl1, setAnchorEl1] = React.useState<null | HTMLElement>(null);

  const style = generateResponsiveStyle();

  const handleLogout = async () => {
    try {
      // await getLogout({}).unwrap();
      removeFromStorage(STORAGE_KEYS.token);
      // dispatch(resetAuth());
      navigate("/", { replace: true });
    } catch (error) {
      console.log(error);
    }
  };

  const open1 = Boolean(anchorEl1);

  const open = Boolean(anchorEl);

  const handleClick1 = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl1(event.currentTarget);
  };

  const handleClose1 = () => {
    setAnchorEl1(null);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const updateNotifications = async (id: string) => {
    const body = {
      isRead: true,
      notificationId: id,
    };

    try {
      const response = (await UpdateNotification(body)) as {
        status: number;
        data: Notification[];
      };
    } catch (error) {
      console.log(error);
    }
  };

  const decideRoute = (notification: Notification) => {
    updateNotifications(notification?._id);
    if (notification?.data?.type === "Customs") {
      if (notification?.data?.postType === "Video") {
        navigate("/manage-customs/video");
      } else {
        navigate("/manage-customs/articles");
      }
    } else {
      if (notification?.data?.postType === "Video") {
        navigate("/manage-gst/video");
      } else {
        navigate("/manage-gst/articles");
      }
    }
  };

  const showMore = () => {
    getNotifications(page + 1);
    setPage((state) => state + 1);
  };

  const getNotifications = async (page: number) => {
    setLoading(true);
    try {
      const response = (await GetNotifications({
        limit: 10,
        skip: (page - 1) * 10,
      })) as {
        status: number;
        count: number;
        data: Notification[];
      };
      setLoading(false);
      if (response.status === 200) {
        setNotifications(
          response?.data?.length ? [...notifications, ...response?.data] : []
        );
        setNotificationCount(response?.count || 0);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const getNotificationCount = async () => {
    try {
      const response = (await GetNotificationCount()) as {
        status: number;
        count: number;
      };
      if (response.status === 200) {
        setCount(response?.count || 0);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (window) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
    const token = getFromStorage(STORAGE_KEYS.token);
    if (token) {
      getNotificationCount();
      getNotifications(page);
    }
  }, []);

  return (
    <Box className="container top_bar">
      <AppBar
        position="fixed"
        className="topbar"
        sx={{
          width: { lg: `calc(100% - ${SIDEBAR_WIDTH}px)` },
          ml: { lg: `${SIDEBAR_WIDTH}px` },
        }}
      >
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          <IconButton
            color="inherit"
            aria-label="open sidebar"
            edge="start"
            onClick={handleSidebarToggle}
            style={{ backgroundColor: "#72cedd" }}
            sx={{
              display: { xs: "flex", lg: "none" },
            }}
          >
            <MenuIcon />
          </IconButton>
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              flexGrow: 1,
              justifyContent: "flex-end",
            }}
          >
            <Box sx={{ mr: 2, position: "relative" }}>
              <div className="notif_bubble">{count || 0}</div>
              <IconButton
                className="notification_btn"
                aria-controls={open1 ? "basic-menu1" : undefined}
                aria-haspopup="true"
                aria-expanded={open1 ? "true" : undefined}
                onClick={handleClick1}
              >
                <NotificationsActiveIcon />
              </IconButton>
              <Menu
                className="notiDropdown_menu"
                id="basic-menu1"
                anchorEl={anchorEl1}
                open={open1}
                onClose={handleClose1}
                MenuListProps={{
                  "aria-labelledby": "basic-button1",
                }}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    mt: 1.5,
                    "& .MuiAvatar-root": {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    "&:before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      right: 20,
                      width: 10,
                      height: 10,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                <Box className="noti_head">
                  <Typography component="h2">Notifications</Typography>
                  {/* <Button
                    className="btn btn_primary sm"
                    // onClick={() => navigate("/recieved-notifications")}
                  >
                    See All
                  </Button> */}
                </Box>
                <ul className="noti_list">
                  {notifications?.map((item) => (
                    <li
                      key={item?._id}
                      style={{
                        backgroundColor: item?.isRead ? "#fff" : "#e9e9e9",
                      }}
                      onClick={() => decideRoute(item)}
                    >
                      <div className="noti_info">
                        <Typography component="h3">
                          {item?.title || ""}
                          <Typography component="span">
                            {moment(item?.createdAt || "").fromNow()}
                          </Typography>
                        </Typography>
                        <Typography component="p">
                          {item?.body || ""}
                        </Typography>
                      </div>
                    </li>
                  ))}
                </ul>
                {notificationCount > notifications?.length ? (
                  <>
                    {loading ? (
                      <figure>
                        <img src="/static/images/loader.gif" alt="loading" />
                      </figure>
                    ) : (
                      <div onClick={showMore} className="show_more">
                        Show More
                      </div>
                    )}
                  </>
                ) : null}
              </Menu>
            </Box>

            <Box>
              <IconButton
                className="topbar_user_btn"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
              >
                <img
                  src={
                    // user?.image
                    // ? user?.image
                    // :
                    "/static/images/admin.png  "
                  }
                  alt=""
                />
                <KeyboardArrowDownIcon />
              </IconButton>
              <Menu
                className="userDropdown_menu"
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    mt: 1.5,
                    "& .MuiAvatar-root": {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    "&:before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      right: 10,
                      width: 10,
                      height: 10,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                <MenuItem
                  className="userDropdown_menu_list"
                  onClick={() => setOpenModal(true)}
                >
                  <IconButton className="drpdwn_icns">
                    <LogoutIcon />
                  </IconButton>
                  Logout
                </MenuItem>
              </Menu>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="logout_mdl"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ textAlign: "center", fontSize: "20px", color: "#1c6975" }}
          >
            Are you sure, you want to logout?
          </Typography>
          <div className="flexdiv">
            <Button
              className="yesBtn"
              sx={{ color: "#252525", border: "1px solid #252525" }}
              onClick={() => {
                setOpenModal(false);
                handleLogout();
              }}
            >
              Yes
            </Button>

            <Button
              sx={{ color: "#252525", border: "1px solid #252525" }}
              onClick={() => setOpenModal(false)}
            >
              No
            </Button>
          </div>
        </Box>
      </Modal>
    </Box>
  );
}

export default Topbar;
