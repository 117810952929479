const local = "http://localhost:5001";
const live = "https://api.justidt.com";

export const API_URL = live;

export const END_POINTS = {
  // auth
  login: "/login",

  //users
  user: "/admin/user",
  updateUser: "/admin/update-user-status",

  //dashboard
  dashboard: "/admin/dashboard",

  //notification
  notification: "/notification",
  notificationCount: "/notification/count",
  //articles
  comment: "/admin/comment",
  discussion: "/admin/discussion",
  updateComment: "/admin/updateComment",
  article: "/admin/article",
  addArticle: "/admin/article",
  articleLike: "/admin/getLike",
  updateArticle: "/admin/update-artilce-status",
  categories: "admin/category",
  subscription: "admin/subscription",
  deleteArticle: "admin/delele-article",
  getReport: "admin/report",
  getComment: "admin/comment",
  addFcm: "admin/fcm",
    postUpdate:"admin/discussion"

};
