/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import MainContainer from "../../../layout/MainContainer";
import { Box, Button } from "@mui/material";
import Grid from "@mui/material/Grid";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import { Article } from "../../../types/General";
import { Loader, showError } from "../../../constants";
import { Listing } from "../../../components";
import { CUSTOM } from "../../../constants/categories";
import { GetArticles } from "../../../services/home";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";

const ManageCustomsArticles = () => {
  const navigate = useNavigate();

  const [allArticles, setAllArticles] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [opened, setOpened] = useState<boolean>(false);
  const [postStatus, setPostStatus] = useState<string>("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>("");
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [totalCount, setTotalCount] = useState<number>(0);
  const [startDate, setStartDate] = useState<string | null>(null);
  const [endDate, setEndDate] = useState<string | null>(null);
  const [page, setPage] = useState(1);
  let totalPages = Math.ceil(totalCount / 10);

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  const getArticles = async (
    status: string,
    startDate: string,
    endDate: string
  ) => {
    try {
      setLoading(true);
      const response = (await GetArticles({
        limit: 10,
        skip: (page - 1) * 10,
        postType: "Article",
        categoriesId: CUSTOM,
        status,
        startDate,
        endDate,
        search: debouncedSearchTerm,
      })) as {
        status: number;
        count: number;
        data: Article[];
      };
      if (response?.status === 200) {
        setLoading(false);
        setAllArticles(response?.data || []);
        setTotalCount(response?.count || 10);
        console.log(response?.data);
        setLoading(false);
      } else {
        setLoading(false);
        setAllArticles([]);
      }
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      showError(error?.data?.message || "");
    }
  };

  const applyFilter = () => {
    getArticles(
      postStatus,
      startDate ? dayjs(startDate).format("YYYY-MM-DD") : "",
      endDate ? dayjs(endDate).format("YYYY-MM-DD") : ""
    );
    setOpened(false);
  };

  const resetValues = () => {
    setPostStatus("");
    setStartDate(null);
    setEndDate(null);
    setPage(1);
    getArticles("", "", "");
    setOpened(false);
  };

  useEffect(() => {
    getArticles(
      postStatus,
      startDate ? dayjs(startDate).format("YYYY-MM-DD") : "",
      endDate ? dayjs(endDate).format("YYYY-MM-DD") : ""
    );
  }, [debouncedSearchTerm, page]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <MainContainer>
        <Loader isLoad={loading} />
        <div onClick={() => setOpened(false)} className="main_loyout">
          <div className="dashboard">
            <div className="heading-bread">
              <h1 className="mn_hdng">Custom Articles</h1>

              <Breadcrumbs aria-label="breadcrumb">
                <Link underline="hover" color="inherit" href="/">
                  Custom Articles management
                </Link>
                <Link
                  underline="hover"
                  color="inherit"
                  href="/material-ui/getting-started/installation/"
                >
                  Custom Articles List
                </Link>
              </Breadcrumbs>
            </div>
            <Box className="cards_header_right">
              <Button
                className="btn btn_primary"
                onClick={() => navigate(`/manage-articles/${CUSTOM}/add`)}
              >
                Add Article
              </Button>
            </Box>
          </div>
          <Listing
            applyFilter={applyFilter}
            endDate={endDate}
            listing={allArticles || []}
            opened={opened}
            onPageChange={onPageChange}
            page={page}
            postStatus={postStatus}
            resetValues={resetValues}
            searchTerm={searchTerm}
            setAllListings={setAllArticles}
            setDebouncedSearchTerm={setDebouncedSearchTerm}
            setEndDate={setEndDate}
            setLoading={setLoading}
            setOpened={setOpened}
            setPostStatus={setPostStatus}
            setSearchTerm={setSearchTerm}
            setStartDate={setStartDate}
            getArticles={getArticles}
            startDate={startDate}
            totalPages={totalPages}
          />
        </div>
      </MainContainer>
    </LocalizationProvider>
  );
};

export default ManageCustomsArticles;
