import { Dispatch, SetStateAction, useState } from "react";
import {
  Box,
  Button,
  Card,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Article } from "../types/General";
import moment from "moment";
import { DeleteArticle, UpdateArticleStatus } from "../services/home";
import { showError, showToast } from "../constants";
import SearchBar from "./SearchBar";
import { DatePicker } from "@mui/x-date-pickers";
import { isValidInput } from "../utils/validations";
import Pagination from "./Pagination";
import { useLocation, useNavigate } from "react-router-dom";
import { CUSTOM, GST } from "../constants/categories";
import WarnModal from "./WarnModal";

const Listing = ({
  listing,
  opened,
  page,
  searchTerm,
  endDate,
  postStatus,
  startDate,
  totalPages,
  applyFilter,
  onPageChange,
  resetValues,
  setEndDate,
  setPostStatus,
  setStartDate,
  setOpened,
  setAllListings,
  setDebouncedSearchTerm,
  setLoading,
  setSearchTerm,
  getArticles,
}: {
  listing: Article[];
  page: number;
  searchTerm: string;
  opened: boolean;
  startDate: string | null;
  endDate: string | null;
  postStatus: string;
  totalPages: number;
  setPostStatus: Dispatch<SetStateAction<string>>;
  setEndDate: Dispatch<SetStateAction<string | null>>;
  setStartDate: Dispatch<SetStateAction<string | null>>;
  setLoading: Dispatch<SetStateAction<boolean>>;
  setOpened: Dispatch<SetStateAction<boolean>>;
  setAllListings: Dispatch<SetStateAction<Article[]>>;
  setDebouncedSearchTerm: Dispatch<SetStateAction<string>>;
  setSearchTerm: Dispatch<SetStateAction<string>>;
  resetValues: () => void;
  applyFilter: () => void;
  onPageChange: (page: number) => void;
  getArticles: (status: string, startDate: string, endDate: string) => void;
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [open, setOpen] = useState(false);
  const [selectedId, setSelectedId] = useState("");

  const handleStatusChange = async (status: string, id: string) => {
    try {
      if (status === "DISCARD") {
        setSelectedId(id);
        setOpen(true);
      } else if (status === "View") {
        return;
      }

      setLoading(true);
      const response = (await UpdateArticleStatus(id, { status })) as {
        status: number;
      };

      if (response?.status === 200) {
        setLoading(false);
        showToast("Status updated successfully");
        let articles = [...listing];
        let idx = -1;
        idx = articles?.findIndex((item) => item?._id === id);
        if (idx > -1) {
          articles[idx] = {
            ...articles[idx],
            status,
          };
        }
        setAllListings([...articles]);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      showError("something went wrong");
      console.log(error);
    }
  };

  const discardPost = async () => {
    try {
      if (selectedId === "") {
        showError("Please try again");
        return;
      }
      setLoading(true);
      const response = (await DeleteArticle(selectedId)) as {
        status: number;
      };
      if (response?.status === 200) {
        setLoading(false);
        showToast(`Article deleted successfully`);
        getArticles("", "", "");
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);

      console.log(error);
    }
  };

  return (
    <div>
      <Card className="cards">
        <Box className="cards_header">
          <Grid
            container
            spacing={2}
            sx={{ mb: 2 }}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Grid item>
              <SearchBar
                placeholder="Search by article or author"
                searchTerm={searchTerm}
                setDebouncedSearchTerm={setDebouncedSearchTerm}
                value={searchTerm}
                onChange={(val: any) => {
                  if (isValidInput(val.target.value)) {
                    onPageChange(1);
                    setSearchTerm(val.target.value);
                  }
                }}
              />
            </Grid>
            <Grid item>
              <div className="parent-box">
                <Button
                  onClick={(e: any) => {
                    e.stopPropagation();
                    setOpened(true);
                  }}
                  id="basic-button"
                  variant="contained"
                >
                  Filter
                </Button>
                {opened ? (
                  <div
                    onClick={(e) => e.stopPropagation()}
                    className="filter-options"
                  >
                    <h3>Filter Options</h3>
                    <div className="filter-div">
                      <FormControl fullWidth>
                        <label>Status</label>
                        <select
                          onChange={(val) => setPostStatus(val.target.value)}
                          value={postStatus}
                          name="cars"
                          id="cars"
                        >
                          <option value="">Any</option>
                          <option value="Published">Published</option>
                          <option value="Received">Recieved</option>
                          <option value="Draft">Draft</option>
                          <option value="Disabled">Disabled</option>
                        </select>
                      </FormControl>
                      <FormControl fullWidth>
                        <label>Period</label>
                        <div
                          className="range-picker"
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "20px",
                          }}
                        >
                          <DatePicker
                            sx={{ height: "40px" }}
                            value={startDate}
                            onChange={(val) => {
                              console.log(val);
                              setStartDate(val);
                            }}
                            disableFuture
                            format="DD/MM/YYYY"
                            label="From"
                          />
                          <DatePicker
                            sx={{ height: "40px" }}
                            value={endDate}
                            minDate={startDate}
                            onChange={(val) => {
                              console.log(val, "start date");

                              setEndDate(val);
                            }}
                            disableFuture
                            format="DD/MM/YYYY"
                            label="Till"
                          />
                        </div>
                      </FormControl>

                      <div className="button-div">
                        <Button onClick={resetValues} id="basic-button">
                          Reset
                        </Button>
                        <Button
                          onClick={applyFilter}
                          id="basic-button"
                          variant="contained"
                        >
                          Apply
                        </Button>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </Grid>
          </Grid>
        </Box>
        <TableContainer className="table_container">
          <Box className="heading"></Box>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="left">HEADLINE</TableCell>
                <TableCell align="center">UID</TableCell>
                <TableCell align="center">AUTHOR</TableCell>
                <TableCell align="center">POSTED</TableCell>
                {/* <TableCell align="center">CREATE</TableCell> */}

                <TableCell align="center">COMMENTS</TableCell>
                <TableCell align="center">Status</TableCell>
                <TableCell align="center">Actions</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {listing?.length ? (
                listing?.map((item: any, i: any) => {
                  const words = item?.title.split(" ");

                  // Take the first six words
                  const truncatedTitle = words.slice(0, 6).join(" ");

                  // If the title has more than six words, add an ellipsis
                  const displayTitle =
                    words.length > 6 ? truncatedTitle + "..." : truncatedTitle;

                  return (
                    <TableRow>
                      <TableCell align="left">{displayTitle}</TableCell>
                      <TableCell align="center">
                        {item?.uuid || "N/A"}
                      </TableCell>
                      <TableCell align="center">
                        {" "}
                        {item?.author?.length
                          ? item?.author?.replace("JustIdt", "JustIDT")
                          : "N/A"}
                      </TableCell>
                      <TableCell align="center">
                        {moment(item?.updatedAt).format("MMM DD, YYYY (HH:mm)")}
                      </TableCell>
                      {/* <TableCell align="center">
                        {moment(item?.createdAt).format("MMM DD, YYYY (HH:mm)")}
                      </TableCell> */}
                      <TableCell align="center">
                        {item?.commentCount || 0}
                      </TableCell>
                      <TableCell align="center">
                        <span className="status active">{item?.status}</span>
                      </TableCell>
                      <TableCell align="center">
                        <Box className="table_actions">
                          <Select
                            className="select_div"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={""}
                            style={{ width: "92px" }}
                            inputProps={{
                              "aria-label": "Without label",
                            }}
                            displayEmpty
                            onChange={(val) =>
                              handleStatusChange(val.target.value, item?._id)
                            }
                          >
                            <MenuItem
                              className="action-item"
                              style={{ display: "none" }}
                              disabled
                              value=""
                            >
                              Actions{" "}
                            </MenuItem>
                            <MenuItem
                              className="action-item"
                              onClick={() => {
                                if (location?.pathname?.includes("article")) {
                                  navigate(
                                    `/manage-articles/${location?.pathname?.includes("custom") ? CUSTOM : GST}/edit/${item?._id}`
                                  );
                                } else {
                                  navigate(
                                    `/manage-videos/${location?.pathname?.includes("custom") ? CUSTOM : GST}/edit/${item?._id}`
                                  );
                                }
                              }}
                              value="View"
                            >
                              Edit
                            </MenuItem>
                            <MenuItem
                              style={{
                                display:
                                  item?.status === "Draft" ||
                                  item?.status === "Received"
                                    ? "block"
                                    : "none",
                              }}
                              className="action-item"
                              value="DISCARD"
                            >
                              Discard
                            </MenuItem>
                            <MenuItem
                              style={{
                                display:
                                  item?.status === "Published"
                                    ? "block"
                                    : "none",
                              }}
                              className="action-item"
                              value="Disabled"
                            >
                              Disable
                            </MenuItem>
                            <MenuItem
                              className="action-item"
                              value="Published"
                              style={{
                                display:
                                  item?.status === "Disabled"
                                    ? "block"
                                    : "none",
                              }}
                            >
                              Publish
                            </MenuItem>
                          </Select>
                        </Box>
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell
                    align="center"
                    colSpan={10}
                    sx={{ color: "#051140" }}
                  >
                    {`No ${location?.pathname?.includes("article") ? "Article" : "Video"} Found`}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
      <Pagination
        module={listing}
        page={page}
        onPageChange={onPageChange}
        totalPages={totalPages}
      />
      <WarnModal
        open={open}
        setOpen={setOpen}
        handleDelete={discardPost}
        name="Article"
      />
    </div>
  );
};

export default Listing;
