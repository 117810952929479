import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Button, Card, CardContent, Grid } from "@mui/material";
import MainContainer from "../../../layout/MainContainer";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import { useEffect, useState } from "react";
import { Customers } from "../../../types/General";
import {
  GetSubscriptionById,
  GetUser,
  UpdateUserStatus,
} from "../../../services/home";
import MailIcon from "@mui/icons-material/Mail";
import ContactsIcon from "@mui/icons-material/Contacts";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { Loader, showError, showToast } from "../../../constants";
import moment from "moment";
import axios from "axios";

const UserDetails = () => {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);
  const [userData, setUserData] = useState<any | null>(null);
  const [subData, setSubData] = useState<any | null>(null);

  const showStatus = (status: string) => {
    if (status === "DELETED") {
      return "Disabled";
    } else if (status === "BLOCK") {
      return "Banned";
    } else {
      return "Active";
    }
  };

  const updateStatus = async (status: string) => {
    try {
      setLoading(true);

      if (!id?.length) {
        return;
      }
      const response = (await UpdateUserStatus(id, { status })) as {
        status: number;
      };

      if (response?.status === 200) {
        setLoading(false);
        showToast("Status updated successfully");
        setUserData({
          ...userData,
          status,
        } as any);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      showError("Something went wrong");
      console.log(error);
    }
  };

  const rehydrateValues = async () => {
    try {
      setLoading(true);

      if (!id?.length) {
        return;
      }
      const response = (await GetUser(id)) as {
        code: number;
        data: Customers[];
      };

      if (response?.code === 200) {
        if (response?.data?.length) {
          setUserData(response?.data?.[0]);
        }
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      showError("Something went wrong");
      console.log(error);
    }
  };

  useEffect(() => {
    GetSubscription(id);
    rehydrateValues();
  }, []);
  const GetSubscription = async (userId: any) => {
    try {
      setLoading(true);

      const subscriptionData = await axios.get(
        `https://api.revenuecat.com/v1/subscribers/${userId}`,
        {
          headers: {
            Authorization: "Bearer sk_XSOZEfvLvsKbYzeZybMdGQTeQkMZJ",
          },
        }
      );
      
      // console.log(subscriptionData?.data?.subscriber?.subscriptions?.anual_insider_subscription || subscriptionData?.data?.subscriber?.subscriptions?.insider_subscription); // Access the response data using subscriptionData.data
      setSubData(subscriptionData?.data?.subscriber?.subscriptions?.anual_insider_subscription || subscriptionData?.data?.subscriber?.subscriptions?.insider_subscription);
      setLoading(false);

    } catch (error) {
      console.error("Error fetching subscription data:", error);
      // Handle error here
    }
  };

  return (
    <MainContainer>
      <Loader isLoad={loading} />
      <div>
        <div className="main_loyout">
          <div className="dashboard">
            <div className="heading-bread">
              <h1 className="mn_hdng">User Overview</h1>
              <Breadcrumbs aria-label="breadcrumb">
                <Link underline="hover" color="inherit" href="/manage-users">
                  User management
                </Link>
                <Link
                  underline="hover"
                  color="inherit"
                // href="/material-ui/getting-started/installation/"
                >
                  User Overview
                </Link>
              </Breadcrumbs>
            </div>
            <Button
              style={{ background: "#0d0e12" }}
              className="btn btn_primary"
              onClick={() => navigate("/manage-users")}
            >
              Go Back
            </Button>
          </div>
          <Card className="cards">
            <CardContent sx={{ sp: 1 }}>
              <Grid container lg={12} md={12} sm={12} xs={12} spacing={2}>
                <Grid item lg={2} md={3} sm={4} xs={12}>
                  <figure className="user_img" style={{ margin: 0 }}>
                    <img
                      src={
                        userData?.profileImage?.length
                          ? userData?.profileImage
                          : "https://preview.keenthemes.com/metronic8/react/demo1//media/avatars/300-1.jpg"
                      }
                      style={{
                        height: "auto",
                        width: "170px",
                        borderRadius: "10px",
                        marginRight: "20px",
                      }}
                      alt=""
                    />
                  </figure>
                </Grid>
                <Grid item lg={7} md={7} sm={6} xs={12}>
                  <div
                    style={{ display: "grid", gap: "24px", marginLeft: "20px" }}
                  >
                    <div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "16px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "0px",
                          }}
                        >
                          <span
                            style={{
                              color: "#78829d",
                              fontSize: "14px",
                            }}
                          >{`#${userData?.uuid || ""}`}</span>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "12px",
                            }}
                          >
                            <span
                              style={{ fontSize: "20px", fontWeight: "700" }}
                            >
                              {userData?.username || ""}
                            </span>
                            <span
                              className={`status ${showStatus(userData?.status || "")}`}
                            >
                              {showStatus(userData?.status || "")}
                            </span>
                          </div>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            gap: "12px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "4px",
                            }}
                          >
                            <AccountCircleIcon
                              style={{
                                width: "24px",
                                height: "24px",
                                color: "#99a1b7",
                              }}
                            />
                            <span
                              style={{
                                fontSize: "14px",
                                color: "#78829d",
                                fontWeight: "400",
                              }}
                            >
                              {userData?.role === "USER"
                                ? "user"
                                : userData?.role || ""}
                            </span>
                          </div>

                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "4px",
                            }}
                          >
                            <ContactsIcon
                              style={{
                                width: "20px",
                                height: "20px",
                                color: "#99a1b7",
                              }}
                            />
                            <span
                              style={{
                                fontSize: "14px",
                                color: "#78829d",
                                // fontWeight: "300",
                              }}
                            >
                              {userData?.phoneNumber || "N/A"}
                            </span>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "4px",
                            }}
                          >
                            <MailIcon
                              style={{
                                width: "24px",
                                height: "24px",
                                color: "#99a1b7",
                              }}
                            />
                            <span
                              style={{
                                fontSize: "14px",
                                color: "#78829d",
                                fontWeight: "400",
                              }}
                            >
                              {userData?.email || ""}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div style={{ display: "flex", gap: "25px" }}>
                      <div
                        className="cards"
                        style={{
                          padding: "16px 24px",
                          minWidth: "125px",
                          borderStyle: "dashed",
                          borderColor: "rgb(219 219 219)",
                          boxShadow: "none",
                        }}
                      >
                        <div className="like-count">
                          {/* <ForumIcon /> */}
                          <div>
                            <h3
                              style={{
                                fontSize: "24px",
                                lineHeight: "1.2",
                                fontWeight: "600",
                                margin: "0px",
                              }}
                            >
                              {userData?.postCount}
                            </h3>
                            <p
                              style={{
                                fontSize: "1rem",
                                color: "#78829d",
                                fontWeight: "600",
                                margin: "0",
                              }}
                            >
                              Posts
                            </p>
                          </div>
                        </div>
                      </div>
                      <div
                        className="cards"
                        style={{
                          padding: "16px 24px",
                          minWidth: "125px",
                          borderStyle: "dashed",
                          borderColor: "rgb(219 219 219)",
                          boxShadow: "none",
                        }}
                      >
                        <div className="like-count">
                          {/* <ThumbUpIcon/> */}
                          <div>
                            <h3
                              style={{
                                fontSize: "24px",
                                lineHeight: "1.2",
                                fontWeight: "600",
                                margin: "0",
                              }}
                            >
                              {userData?.commentLikeCount +
                                userData?.postLikeCount || "0"}
                            </h3>
                            <p
                              style={{
                                fontSize: "1rem",
                                color: "#78829d",
                                fontWeight: "600",
                                margin: "0",
                              }}
                            >
                              Just Points
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Grid>
                <Grid
                  item
                  lg={3}
                  md={2}
                  sm={2}
                  xs={12}
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <Button
                    className="btn btn-sm btn_primary"
                    style={{
                      height: "40px",
                      display:
                        userData?.status === "DELETED" ? "none" : "block",
                    }}
                    onClick={() =>
                      updateStatus(
                        userData?.status === "BLOCK" ? "ACTIVE" : "BLOCK"
                      )
                    }
                  >
                    {userData?.status === "BLOCK" ? "Activate" : "Ban"}
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>

          <div style={{ display: "flex", gap: "30px", marginTop: "30px" }}>
            <Grid container lg={6} md={6} sm={6} xs={12}>
              <Card sx={{ width: "100%" }} className="cards">
                <div>
                  <h4
                    style={{
                      margin: "0px 0px 6px",
                      fontSize: "16px",
                      fontWeight: "700",
                    }}
                  >
                    Headline
                  </h4>
                  <p
                    style={{
                      margin: "0px 0px 20px",
                      fontSize: "14px",
                      color: "rgb(120, 130, 157)",
                      fontWeight: "400",
                    }}
                  >
                    {userData?.headline || "N/A"}
                  </p>
                  <h4
                    style={{
                      margin: "0px 0px 6px",
                      fontSize: "16px",
                      fontWeight: "700",
                    }}
                  >
                    About Yourself
                  </h4>
                  <p
                    style={{
                      margin: "0px",
                      fontSize: "14px",
                      color: "rgb(120, 130, 157)",
                      fontWeight: "400",
                    }}
                  >
                    {userData?.bio || "N/A"}
                  </p>
                </div>
              </Card>
            </Grid>
            <Grid container lg={6} md={6} sm={6} xs={12}>
              <Card style={{ width: "100%" }} className="cards">
                <div>
                  <h4
                    style={{
                      margin: "0px 0px 6px",
                      fontSize: "16px",
                      fontWeight: "700",
                    }}
                  >
                    Membership
                  </h4>
                  <p
                    style={{
                      margin: "0px 0px 20px",
                      fontSize: "14px",
                      color: "rgb(120, 130, 157)",
                      fontWeight: "400",
                    }}
                  >
                    {subData ? (
                      <>
                        <span>
                          {subData.expires_date && new Date(subData.expires_date) <= new Date() ? (
                            "N/A"
                          ) : (
                            `Insider (${moment(subData.purchase_date || new Date()).format("MMM DD, YYYY")} To ${moment(subData.expires_date || new Date()).format("MMM DD, YYYY")})`
                          )}
                        </span>
                      </>
                    ) : (
                      "N/A"
                    )}
                  </p>


                  <h4
                    style={{
                      margin: "0px 0px 6px",
                      fontSize: "16px",
                      fontWeight: "700",
                    }}
                  >
                    Others
                  </h4>
                  <p
                    style={{
                      margin: "0px 0px 6px",
                      fontSize: "14px",
                      color: "rgb(120, 130, 157)",
                      fontWeight: "400",
                    }}
                  >
                    {`Show Phone: ${userData?.showPhonNumber ? "Yes" : "No"}`}
                  </p>
                  <p
                    style={{
                      margin: "0px 0px 6px",
                      fontSize: "14px",
                      color: "rgb(120, 130, 157)",
                      fontWeight: "400",
                    }}
                  >
                    {`Show Email: ${userData?.showEmail ? "Yes" : "No"}`}
                  </p>
                  <p
                    style={{
                      margin: "0px",
                      fontSize: "14px",
                      color: "rgb(120, 130, 157)",
                      fontWeight: "400",
                    }}
                  >
                    {`Login Through: ${userData?.signupType || ""}`}
                  </p>
                </div>
              </Card>
            </Grid>
          </div>
        </div>
      </div>
    </MainContainer>
  );
};

export default UserDetails;
