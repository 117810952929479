export const generateResponsiveStyle = () => {
    return {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: 400,
      bgcolor: "#ffff",
      boxShadow: 24,
      p: 6,
      borderRadius: 3,
      outline: "none",
    };
  };
