/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import MainContainer from "../../layout/MainContainer";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  Card,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Button,
  IconButton,
  TableBody,
  Select,
  MenuItem,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import FormControl from "@mui/material/FormControl";
import SearchBar from "../../components/SearchBar";
import { Article } from "../../types/General";
import { Loader, showError, showToast } from "../../constants";
import { Pagination } from "../../components";
import { isValidInput } from "../../utils/validations";
import { GetArticles, UpdateArticleStatus } from "../../services/home";

const ManageArticles = () => {
  const navigate = useNavigate();

  const [allArticles, setAllArticles] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [postType, setPostType] = useState<string>("");
  const [postStatus, setPostStatus] = useState<string>("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>("");
  const [totalCount, setTotalCount] = useState<number>(0);
  const [page, setPage] = useState(1);
  let totalPages = Math.ceil(totalCount / 10);

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  const getArticles = async () => {
    try {
      setLoading(true);
      const response = (await GetArticles({
        limit: 10,
        skip: (page - 1) * 10,
        postType,
        status: postStatus,
        search: debouncedSearchTerm,
      })) as {
        status: number;
        count: number;
        data: Article[];
      };
      if (response?.status === 200) {
        setLoading(false);
        setAllArticles(response?.data || []);
        setTotalCount(response?.count || 10);
        console.log(response?.data);
        setLoading(false);
      } else {
        setLoading(false);
        setAllArticles([]);
      }
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      showError(error?.data?.message || "");
    }
  };

  const handleStatusChange = async (status: string, id: string) => {
    try {
      setLoading(true);

      const response = (await UpdateArticleStatus(id, { status })) as {
        status: number;
      };

      if (response?.status === 200) {
        setLoading(false);
        showToast("Status updated successfully");
        let articles = [...allArticles];
        let idx = -1;
        idx = articles?.findIndex((item) => item?._id === id);
        if (idx > -1) {
          articles[idx] = {
            ...articles[idx],
            status,
          };
        }
        console.log(articles, "articles");
        setAllArticles([...articles]);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      showError("something went wrong");
      console.log(error);
    }
  };

  useEffect(() => {
    getArticles();
  }, [debouncedSearchTerm, page, postType, postStatus]);

  return (
    <MainContainer>
      <Loader isLoad={loading} />
      <div className="main_loyout">
        <div className="dashboard">
          <div className="heading-bread">
            <h1 className="mn_hdng">GST Articles</h1>

            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color="inherit" href="/">
                Gst Articles management
              </Link>
              <Link
                underline="hover"
                color="inherit"
                href="/material-ui/getting-started/installation/"
              >
                Gst Articles List
              </Link>
            </Breadcrumbs>
          </div>
        </div>
        <Card className="cards">
          <Box className="cards_header">
            <Grid
              container
              spacing={2}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid item></Grid>

              <Box className="cards_header_right">
                <Button
                  className="btn btn_primary"
                  onClick={() => navigate("/manage-articles/add")}
                >
                  Add Article
                </Button>
              </Box>
            </Grid>
          </Box>
          <TableContainer className="table_container">
            <Box className="heading"></Box>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>HEADLINE</TableCell>
                  <TableCell>UID</TableCell>
                  <TableCell>AUTHOR</TableCell>
                  <TableCell>
                    STATUS <span></span>
                  </TableCell>
                  <TableCell>POSTED</TableCell>
                  <TableCell>COMMENTS</TableCell>
                  <TableCell align="right">Actions</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {allArticles?.length ? (
                  allArticles?.map((item: any, i: any) => {
                    const words = item?.title.split(" ");

                    // Take the first six words
                    const truncatedTitle = words.slice(0, 6).join(" ");

                    // If the title has more than six words, add an ellipsis
                    const displayTitle =
                      words.length > 6
                        ? truncatedTitle + "..."
                        : truncatedTitle;

                    return (
                      <TableRow>
                        <TableCell>
                          {/* {" "}
                          {(page - 1) * 10 + i + 1} */}
                          {displayTitle}
                        </TableCell>

                        <TableCell>123456</TableCell>

                        <TableCell> {item?.author}</TableCell>
                        <TableCell>
                          {/* {moment(new Date()).format("DD/MM/YYYY")} */}
                          {item?.status}
                        </TableCell>
                        <TableCell>
                          {moment(item?.createdAt).format("YYYY-MM-DD")}
                        </TableCell>
                        <TableCell>N/A</TableCell>
                        <TableCell>
                          <span className="status active">{item?.status}</span>
                        </TableCell>
                        <TableCell align="center">
                          {/* <Switch
                            {...label}
                            disabled={item?.status === "DELETED"}
                            checked={item?.status === "ACTIVE"}
                            onClick={() =>
                              updateStatus(
                                item?._id,
                                item?.status === "ACTIVE" ? "BLOCK" : "ACTIVE"
                              )
                            }
                            size="small"
                          /> */}
                        </TableCell>
                        <TableCell align="right">
                          <Box className="table_actions">
                            {/* <IconButton
                              onClick={() =>
                                navigate(`/manage-users/${item?._id}`, {
                                  state: { item },
                                })
                              }
                            >
                              <VisibilityIcon />
                            </IconButton> */}
                            <Select
                              className="select_div"
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={"kkk"}
                              style={{ width: "92px" }}
                              inputProps={{
                                "aria-label": "Without label",
                              }}
                              displayEmpty
                              onChange={(val) =>
                                handleStatusChange(val.target.value, item?._id)
                              }
                            >
                              <MenuItem
                                className="action-item"
                                style={{ display: "none" }}
                                disabled
                                value="PENDING"
                              >
                                Actions{" "}
                              </MenuItem>
                              <MenuItem className="action-item" value="ACTIVE">
                                Actions
                              </MenuItem>
                              <MenuItem className="action-item" value="BANNED">
                                Banned
                              </MenuItem>
                              <MenuItem className="action-item" value="DELETED">
                                Disabled
                              </MenuItem>
                              <MenuItem
                                className="action-item"
                                onClick={() => navigate(`/manage-users/`)}
                                value="VIEW"
                              >
                                View
                              </MenuItem>
                            </Select>
                          </Box>
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell
                      align="center"
                      colSpan={10}
                      sx={{ color: "#051140" }}
                    >
                      No Users Found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
        <Pagination
          module={10}
          page={page}
          onPageChange={onPageChange}
          totalPages={totalPages}
        />
      </div>
    </MainContainer>
  );
};

export default ManageArticles;
