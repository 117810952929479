import React from "react";
import { useParams } from "react-router-dom";
import ManageCustomsArticles from "./article";
import ManageCustomVideos from "./video";

const CUSTOMS = () => {
  const { type } = useParams();

  return type && type === "articles" ? (
    <ManageCustomsArticles />
  ) : (
    <ManageCustomVideos />
  );
};

export default CUSTOMS;
