import { useNavigate, useParams } from "react-router-dom";
import { Button, Card, CardContent, Grid } from "@mui/material";
import MainContainer from "../../../layout/MainContainer";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import { useEffect, useState } from "react";
import { Article } from "../../../types/General";
import {
  ArticleLikes,
  GetArticleById,
  GetDiscussionById,
  UpdateArticleStatus,
} from "../../../services/home";
import { Loader, showError, showToast } from "../../../constants";

const ForumPostsDetails = () => {
  const { articleId } = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);
  const [articleData, setArticleData] = useState<any | null>(null);
  const [likes, setLikes] = useState<
    { userName: string; email: string; _id: string }[]
  >([]);

  const showStatus = (status: string) => {
    if (status === "Published") {
      return "Enabled";
    } else if (status === "Disabled") {
      return "Disabled";
    } else {
      return "N/A";
    }
  };

  const updateStatus = async (status: string) => {
    try {
      setLoading(true);
      if (!articleId?.length) {
        return;
      }
      const response = (await UpdateArticleStatus(articleId, { status })) as {
        status: number;
      };

      if (response?.status === 200) {
        setLoading(false);
        showToast("Status updated successfully");
        setArticleData({
          ...articleData,
          status,
        } as any);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      showError("Something went wrong");
      console.log(error);
    }
  };

  const getForumPostsDetails = async () => {
    if (!articleId) {
      return;
    }
    try {
      const response:any = (await GetDiscussionById({ articleId })) as {
        data: Article;
        code: number;
      };
      if (response?.code === 200) {
        setArticleData(response?.data || null);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getArticleLikes = async () => {
    if (!articleId) {
      return;
    }
    try {
      const response = (await ArticleLikes(articleId)) as {
        data: { userName: string; email: string; _id: string }[];
        status: number;
      };
      if (response?.status === 200) {
        setLikes(response?.data || []);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getForumPostsDetails();
    getArticleLikes();
  }, []);

  return (
    <MainContainer>
      <Loader isLoad={loading} />
      <div>
        <div className="main_loyout">
          <div className="dashboard">
            <div className="heading-bread">
              <h1 className="mn_hdng">Post Overview</h1>
              <Breadcrumbs aria-label="breadcrumb">
                <Link underline="hover" color="inherit">
                  Post management
                </Link>
                <Link
                  underline="hover"
                  color="inherit"
                  // href="/material-ui/getting-started/installation/"
                >
                  Post Overview
                </Link>
              </Breadcrumbs>
            </div>
            <Button
              style={{ background: "#0d0e12" }}
              className="btn btn_primary"
              onClick={() => navigate(-1)}
            >
              Go Back
            </Button>
          </div>
          <Card className="cards">
            <CardContent sx={{ sp: 1 }}>
              <Grid container lg={12} md={12} sm={12} xs={12} spacing={2}>
                <Grid item lg={2} md={3} sm={4} xs={12}>
                  <figure className="user_img" style={{ margin: 0 }}>
                    <img
                      src={
                        articleData?.user?.profileImage?.length
                          ? articleData?.user?.profileImage
                          : "https://preview.keenthemes.com/metronic8/react/demo1//media/avatars/300-1.jpg"
                      }
                      style={{
                        height: "auto",
                        width: "170px",
                        borderRadius: "10px",
                        marginRight: "20px",
                      }}
                      alt=""
                    />
                  </figure>
                </Grid>
                <Grid item lg={7} md={7} sm={6} xs={12}>
                  <div
                    style={{ display: "grid", gap: "24px", marginLeft: "20px" }}
                  >
                    <div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "16px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "0px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "12px",
                            }}
                          >
                            <span
                              style={{ fontSize: "20px", fontWeight: "700" }}
                            >
                              {articleData?.author || ""}
                            </span>
                            <span
                              className={`status ${articleData?.status === "Published" ? "Active" : "Disabled"}`}
                            >
                              {showStatus(articleData?.status || "")}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div style={{ display: "flex", gap: "25px" }}>
                      <div
                        className="cards"
                        style={{
                          padding: "16px 24px",
                          minWidth: "125px",
                          borderStyle: "dashed",
                          borderColor: "rgb(219 219 219)",
                          boxShadow: "none",
                        }}
                      >
                        <div className="like-count">
                          {/* <ForumIcon /> */}
                          <div>
                            <h3
                              style={{
                                fontSize: "24px",
                                lineHeight: "1.2",
                                fontWeight: "600",
                                margin: "0px",
                              }}
                            >
                              {articleData?.likeCount + articleData?.commentLikeCount || "0"}
                            </h3>
                            <p
                              style={{
                                fontSize: "1rem",
                                color: "#78829d",
                                fontWeight: "600",
                                margin: "0",
                              }}
                            >
                              Likes
                            </p>
                          </div>
                        </div>
                      </div>
                      <div
                        className="cards"
                        style={{
                          padding: "16px 24px",
                          minWidth: "125px",
                          borderStyle: "dashed",
                          borderColor: "rgb(219 219 219)",
                          boxShadow: "none",
                        }}
                      >
                        <div className="like-count">
                          {/* <ThumbUpIcon/> */}
                          <div>
                            <h3
                              style={{
                                fontSize: "24px",
                                lineHeight: "1.2",
                                fontWeight: "600",
                                margin: "0",
                              }}
                            >
                              {articleData?.commentCount || "0"}
                            </h3>
                            <p
                              style={{
                                fontSize: "1rem",
                                color: "#78829d",
                                fontWeight: "600",
                                margin: "0",
                              }}
                            >
                              Comments
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Grid>
                <Grid
                  item
                  lg={3}
                  md={2}
                  sm={2}
                  xs={12}
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <Button
                    className="btn btn-sm btn_primary"
                    style={{
                      height: "40px",
                    }}
                    onClick={() =>
                      updateStatus(
                        articleData?.status === "Published"
                          ? "Disabled"
                          : "Published"
                      )
                    }
                  >
                    {articleData?.status === "Published" ? "Disable" : "Enable"}
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>

          <div style={{ display: "flex", gap: "30px", marginTop: "30px" }}>
            <Grid container lg={6} md={6} sm={6} xs={12}>
              <Card sx={{ width: "100%" }} className="cards">
                <h4
                  style={{
                    margin: "0px 0px 6px",
                    fontSize: "16px",
                    fontWeight: "700",
                  }}
                >
                  Content
                </h4>
                <p
                  style={{
                    margin: "0px 0px 20px",
                    fontSize: "14px",
                    color: "rgb(120, 130, 157)",
                    fontWeight: "400",
                  }}
                >
                  {articleData?.description || ""}
                </p>
              </Card>
            </Grid>
            <Grid container lg={6} md={6} sm={6} xs={12}>
              <Card style={{ width: "100%" }} className="cards">
                <div className="parent-border-box">
                  <h4
                    style={{
                      margin: "0px 0px 6px",
                      fontSize: "16px",
                      fontWeight: "700",
                    }}
                  >
                    Likes
                  </h4>
                  {likes?.length ? (
                    likes?.map((item) => (
                      <div key={item?._id} className="border-box">
                        <h4>{item?.userName || "N/A"}</h4>
                        <p>{item?.email || "N/A"}</p>
                      </div>
                    ))
                  ) : (
                    <div>N/A</div>
                  )}
                </div>
              </Card>
            </Grid>
          </div>
        </div>
      </div>
    </MainContainer>
  );
};

export default ForumPostsDetails;
