/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import MainContainer from "../../layout/MainContainer";
import {
  Card,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Button,
  TableBody,
  Select,
  MenuItem,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import SearchBar from "../../components/SearchBar";
import { Discussion } from "../../types/General";
import { Loader, showError, showToast } from "../../constants";
import { Pagination } from "../../components";
import { isValidInput } from "../../utils/validations";
import {
  GetDiscussions,
  UpdateArticleStatus,
  UpdateCommentStatus,
  UpdatePostStatus,
} from "../../services/home";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import moment from "moment";

const ForumPosts = () => {
  const navigate = useNavigate();

  const [allPosts, setAllPosts] = useState<Discussion[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [opened, setOpened] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [startDate, setStartDate] = useState<string | null>(null);
  const [endDate, setEndDate] = useState<string | null>(null);
  const [likes, setLikes] = useState<string>("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>("");
  const [totalCount, setTotalCount] = useState<number>(0);
  const [page, setPage] = useState(1);

  let totalPages = Math.ceil(totalCount / 10);

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  const getForumPosts = async (
    likes: string,
    startDate: string,
    endDate: string
  ) => {
    try {
      setLoading(true);
      const response = (await GetDiscussions({
        limit: 10,
        skip: (page - 1) * 10,
        search: debouncedSearchTerm,
        startDate,
        endDate,
        sortBy: likes,
      })) as {
        status: number;
        count: number;
        data: Discussion[];
      };
      if (response?.status === 200) {
        setLoading(false);
        setAllPosts(response?.data || []);
        setTotalCount(response?.count || 10);
        setLoading(false);
      } else {
        setLoading(false);
        setAllPosts([]);
      }
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      showError(error?.data?.message || "");
    }
  };

  const updateStatus = async (status: string, id: string) => {
    try {
      setLoading(true);

      const response = (await UpdatePostStatus(id, {
        status,
      })) as {
        status: number;
      };

      if (response?.status === 200) {
        setLoading(false);
        showToast("Status updated successfully");
        let comments = [...allPosts];
        let idx = -1;
        idx = comments?.findIndex((item) => item?._id === id);
        if (idx > -1) {
          comments[idx] = {
            ...comments[idx],
            status,
          };
        }
        setAllPosts([...comments]);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      showError("something went wrong");
      console.log(error);
    }
  };

  const applyFilter = () => {
    getForumPosts(
      likes,
      startDate ? dayjs(startDate).format("YYYY-MM-DD") : "",
      endDate ? dayjs(endDate).format("YYYY-MM-DD") : ""
    );
    setOpened(false);
  };

  const resetValues = () => {
    setStartDate(null);
    setEndDate(null);
    setPage(1);
    getForumPosts("", "", "");
    setOpened(false);
  };

  useEffect(() => {
    getForumPosts(
      likes,
      startDate ? moment(dayjs(startDate).toDate()).format("DD-MM-YYYY") : "",
      endDate ? moment(dayjs(endDate).toDate()).format("DD-MM-YYYY") : ""
    );
  }, [debouncedSearchTerm, page]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <MainContainer>
        <Loader isLoad={loading} />
        <div onClick={() => setOpened(false)} className="main_loyout">
          <div className="dashboard">
            <div className="heading-bread">
              <h1 className="mn_hdng">Forum Posts</h1>
            </div>
          </div>
          <Card className="cards">
            <Box className="cards_header">
              <Grid
                container
                spacing={2}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Grid item>
                  <SearchBar
                    placeholder="Search by post or author"
                    searchTerm={searchTerm}
                    setDebouncedSearchTerm={setDebouncedSearchTerm}
                    value={searchTerm}
                    onCross={() => setSearchTerm("")}
                    onChange={(val: any) => {
                      if (isValidInput(val.target.value)) {
                        setPage(1);
                        setSearchTerm(val.target.value);
                      }
                    }}
                  />
                </Grid>
                <Grid item>
                  <div className="parent-box">
                    <Button
                      onClick={(e: any) => {
                        e.stopPropagation();
                        setOpened(true);
                      }}
                      id="basic-button"
                      variant="contained"
                    >
                      Filter
                    </Button>
                    {opened ? (
                      <div
                        onClick={(e) => e.stopPropagation()}
                        className="filter-options"
                      >
                        <h3>Sort Options</h3>
                        <div className="filter-div">
                          <FormControl fullWidth>
                            <label>Criteria</label>
                            <select
                              onChange={(val) => setLikes(val.target.value)}
                              value={likes}
                              name="cars"
                              id="cars"
                            >
                              <option value="like">No. of Likes</option>
                              <option value="comment">No. of Comment</option>
                              <option value="">Any</option>
                            </select>
                          </FormControl>
                          <FormControl fullWidth>
                            <label>Period</label>
                            <div
                              className="range-picker"
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                gap: "20px",
                              }}
                            >
                              <DatePicker
                                sx={{ height: "40px" }}
                                value={startDate}
                                onChange={(val) => {
                                  setStartDate(val);
                                }}
                                disableFuture
                                format="DD/MM/YYYY"
                                label="From"
                              />
                              <DatePicker
                                sx={{ height: "40px" }}
                                value={endDate}
                                minDate={startDate}
                                onChange={(val) => {
                                  setEndDate(val);
                                }}
                                disableFuture
                                format="DD/MM/YYYY"
                                label="Till"
                              />
                            </div>
                          </FormControl>

                          <div className="button-div">
                            <Button onClick={resetValues} id="basic-button">
                              Reset
                            </Button>
                            <Button
                              onClick={applyFilter}
                              id="basic-button"
                              variant="contained"
                            >
                              Apply
                            </Button>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </Grid>
              </Grid>
            </Box>
            <TableContainer className="table_container">
              <Box className="heading"></Box>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">AUTHOR</TableCell>
                    <TableCell align="center">UID</TableCell>
                    <TableCell align="center">POST</TableCell>
                    <TableCell align="center">POSTED</TableCell>
                    <TableCell align="center">LIKES</TableCell>
                    <TableCell align="center">COMMENTS</TableCell>
                    <TableCell align="right">Actions</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {allPosts?.length ? (
                    allPosts?.map((item, i) => {
                      return (
                        <TableRow sx={{ cursor: "pointer" }}>
                          <TableCell align="left">
                            {item?.author || "N/A"}
                          </TableCell>
                          <TableCell align="center">
                            {item?.uuid || "N/A"}
                          </TableCell>
                          <TableCell align="center">
                            {item?.title || "N/A"}
                          </TableCell>
                          <TableCell align="center">
                            {moment(item?.updatedAt).format(
                              "MMM DD, YYYY (HH:mm)"
                            )}
                          </TableCell>
                          <TableCell align="center">
                            {item?.postLikeCount + item.commentLikeCount || 0}
                          </TableCell>
                          <TableCell align="center">
                            {item?.commentCount || 0}
                          </TableCell>
                          <TableCell align="right">
                            <Box className="table_actions">
                              <Select
                                className="select_div"
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={""}
                                style={{ width: "92px" }}
                                inputProps={{
                                  "aria-label": "Without label",
                                }}
                                displayEmpty
                                onChange={(val) => {
                                  if (val.target.value !== "VIEW") {
                                    updateStatus(val.target.value, item?._id);
                                  }
                                }}
                              >
                                <MenuItem
                                  className="action-item"
                                  style={{ display: "none" }}
                                  disabled
                                  value=""
                                >
                                  Actions{" "}
                                </MenuItem>
                                <MenuItem
                                  className="action-item"
                                  value={
                                    item?.status === "Published"
                                      ? "Disabled"
                                      : "Published"
                                  }
                                >
                                  {item?.status === "Published"
                                    ? "Disable"
                                    : "Enable"}
                                </MenuItem>
                                <MenuItem
                                  className="action-item"
                                  onClick={() =>
                                    navigate(`/forum-posts/${item?._id}`, {
                                      state: { item },
                                    })
                                  }
                                  value="VIEW"
                                >
                                  View
                                </MenuItem>
                              </Select>
                            </Box>
                          </TableCell>
                        </TableRow>
                      );
                    })
                  ) : (
                    <TableRow>
                      <TableCell
                        align="center"
                        colSpan={10}
                        sx={{ color: "#051140" }}
                      >
                        No Article Found
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Card>
          <Pagination
            module={allPosts || []}
            page={page}
            onPageChange={onPageChange}
            totalPages={totalPages}
          />
        </div>
      </MainContainer>
    </LocalizationProvider>
  );
};

export default ForumPosts;
