/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import moment from "moment";
import MainContainer from "../../layout/MainContainer";
import {
  Card,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  TableBody,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import { Article } from "../../types/General";
import { Loader, showError } from "../../constants";
import { Pagination, SearchBar } from "../../components";
import { GetReport } from "../../services/report";
import { isValidInput } from "../../utils/validations";
import { useNavigate } from "react-router-dom";

const Reported = () => {
  const navigate = useNavigate();

  const [allReport, setAllReport] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>("");
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [page, setPage] = useState(1);

  let totalPages = Math.ceil(totalCount / 10);

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  const getArticles = async () => {
    try {
      setLoading(true);
      const response = (await GetReport({
        limit: 10,
        skip: page - 1,
        search: searchTerm,
      })) as {
        status: number;
        count: number;
        data: Article[];
      };

      if (response?.status === 200) {
        setLoading(false);
        setAllReport(response?.data || []);
        setTotalCount(response?.count || 10);
        setLoading(false);
      } else {
        setLoading(false);
        setAllReport([]);
      }
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      showError(error?.data?.message || "");
    }
  };

  useEffect(() => {
    getArticles();
  }, [debouncedSearchTerm, page]);

  return (
    <MainContainer>
      <Loader isLoad={loading} />
      <div className="main_loyout">
        <div className="dashboard">
          <div className="heading-bread">
            <h1 className="mn_hdng">Reported</h1>

            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color="inherit" href="/">
                Report management
              </Link>
              <Link
                underline="hover"
                color="inherit"
                href="/material-ui/getting-started/installation/"
              >
                Report List
              </Link>
            </Breadcrumbs>
          </div>
        </div>
        <Card className="cards">
          <Box className="cards_header">
            <Grid
              container
              spacing={2}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid item>
                <SearchBar
                  placeholder="Search by Name or Email"
                  searchTerm={searchTerm}
                  setDebouncedSearchTerm={setDebouncedSearchTerm}
                  value={searchTerm}
                  onCross={() => setSearchTerm("")}
                  onChange={(val: any) => {
                    if (isValidInput(val.target.value)) {
                      setPage(1);
                      setSearchTerm(val.target.value);
                    }
                  }}
                />
              </Grid>
            </Grid>
          </Box>
          <TableContainer className="table_container">
            <Box className="heading"></Box>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>REPORTER</TableCell>
                  <TableCell>CONTENT</TableCell>
                  <TableCell>CONTENT UID</TableCell>
                  <TableCell>
                    AUTHOR <span></span>
                  </TableCell>
                  <TableCell>REASON</TableCell>
                  <TableCell>REPORTED</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {allReport &&
                  allReport.length > 0 &&
                  allReport.map((item: any, index: number) => {
                    return (
                      <TableRow
                        key={index}
                        sx={{ cursor: "pointer" }}
                        onClick={() =>
                          navigate(`/comments/${item?.commentId}`, {
                            state: { item },
                          })
                        }
                      >
                        <TableCell>{item?.user?.username}</TableCell>
                        <TableCell> COMMENT</TableCell>
                        <TableCell>{item?.uuid}</TableCell>
                        <TableCell>{item?.authorDetails?.username}</TableCell>
                        <TableCell>{item?.reportMassage}</TableCell>
                        <TableCell>
                          {moment(item?.updatedAt).format(
                            "MMM DD, YYYY (HH:mm)"
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
        <Pagination
          module={allReport || []}
          page={page}
          onPageChange={onPageChange}
          totalPages={totalPages}
        />
      </div>
    </MainContainer>
  );
};

export default Reported;
