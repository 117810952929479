
import { END_POINTS } from "../../constants/url";
import { get } from "../../utils/AxiosMethods"

export const GetReport = async ({ limit, search, skip }: { limit: number; skip: number; search: string }) => {
    try {
        const response = await get(`${END_POINTS.getReport}?limit=${limit}&skip=${skip}&search=${search}`);
        return response;
    } catch (error) {
        console.log(error);
    }
}