import { useEffect, useState } from "react";
import { Button, Card, Grid, TextField } from "@mui/material";

import * as Yup from "yup";

import MainContainer from "../../../layout/MainContainer";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import { isValidInputWithSC } from "../../../utils/validations";
import {
  AddArticle,
  DeleteArticle,
  GetArticleById,
  UpdateArticle,
} from "../../../services/home";
import { Loader, showError, showToast } from "../../../constants";
import { Article } from "../../../types/General";
import CloseIcon from "@mui/icons-material/Close";
import { WarnModal } from "../../../components";
import ReactQuill, { Quill } from "react-quill";
interface CustomReactQuillProps extends ReactQuill.ReactQuillProps {
  onPaste?: (event: React.ClipboardEvent<HTMLDivElement>) => void;
}
const VideoForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { categoryId } = useParams();
  const { articleId } = useParams();

  const [video, setVideo] = useState<File | string>("");
  const [videoPreview, setVideoPreview] = useState("");
  const [thumbnail, setThumbnail] = useState<File | string>("");
  const [thumbnailPreview, setThumbnailPreview] = useState("");
  const [headline, setHeadline] = useState("");
  const [description, setDescription] = useState("");
  const [status, setStatus] = useState("Published");
  const [otherDetails, setOtherDetails] = useState<Article | null>(null);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      headlineText: headline || "",
      // description: description || "",
    },
    validationSchema: Yup.object({
      headlineText: Yup.string()
        .required("Headline text is required")
        .max(100, "Maximum 100 character are allowed"),
      // description: Yup.string()
      //   .required("Description is required")
      //   .max(1000, "Maximum 1000 character are allowed"),
    }),
    onSubmit: async (values) => {
      formik.setSubmitting(true);

      if (!videoPreview?.length) {
        showError("Video is required");
        return;
      }
      submitVideos(status);
    },
  });

  const submitVideos = async (status:any) => {
    const formData = new FormData();

    formData.append("video", video);
    formData.append("thumbnail", thumbnail);
    formData.append("description", description);
    formData.append("title", formik.values.headlineText);
    formData.append("categoriesId", categoryId || "");
    formData.append("postType", "Video");
    formData.append("status", status);

    setLoading(true);

    try {
      let response: any;
      let status = "";
      if (articleId) {
        response = (await UpdateArticle(formData, articleId)) as {
          status: number;
        };
        status = "updated";
      } else {
        response = (await AddArticle(formData)) as { status: number };
        status = "created";
      }
      setLoading(false);
      if (response?.status === 201) {
        showToast(`Video ${status} successfully`);
        navigate(-1);
      }
    } catch (error: any) {
      setLoading(false);
      console.log(error);
    }
  };

  function createObjectURL(object: File) {
    return window.URL
      ? window.URL.createObjectURL(object)
      : window.webkitURL.createObjectURL(object);
  }

  const handleThumbnailUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event?.target?.files?.length) {
      const reader = new FileReader();
      const file = event?.target?.files[0];
      if (file && file.type.includes("image")) {
        reader.onload = async (event) => {
          const image = new Image();
          image.src = event?.target?.result as string;
          image.onload = () => {
            if (image.width >= 1920 && image.height >= 1080) {
              let url = createObjectURL(file);
              setThumbnailPreview(url);
              setThumbnail(file);
            } else {
              showError(
                `Image must be 1920x1080 resolution. This image has ${image.width}x${image.height} resolution`
              );
            }
          };
        };
        reader.readAsDataURL(file);
      } else {
        showError("Only image types are allowed");
      }
    }
  };

  const handleVideoUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event?.target?.files?.length) {
      const file = event?.target?.files[0];

      if (file && file.type.includes("video")) {
        let url = createObjectURL(file);
        setVideoPreview(url);
        setVideo(file);
      } else {
        showError("Only video types are allowed");
      }
    }
  };

  const getArticleDetails = async () => {
    if (!articleId) {
      return;
    }
    try {
      setLoading(true);

      const response = (await GetArticleById({ articleId })) as {
        data: Article;
        code: number;
      };
      setLoading(false);

      if (response?.code === 200) {
        setHeadline(response?.data?.title || "");
        setDescription(response?.data?.description || "");
        setVideo(response?.data?.video || "");
        setVideoPreview(response?.data?.video || "");
        setThumbnail(response?.data?.thumbnail || "");
        setThumbnailPreview(response?.data?.thumbnail || "");
        setOtherDetails(response?.data || null);
      }
    } catch (error) {
      setLoading(false);

      console.log(error);
    }
  };

  const discardArticle = async () => {
    try {
      if (!articleId) {
        return;
      }
      setLoading(true);

      const response = (await DeleteArticle(articleId)) as { status: number };
      setLoading(false);

      if (response?.status === 200) {
        showToast(`Article deleted successfully`);
        navigate(-1);
      }
    } catch (error) {
      setLoading(false);

      console.log(error);
    }
  };

  useEffect(() => {
    if (location?.pathname?.includes("edit")) {
      getArticleDetails();
    }
  }, []);
  const handlePaste = (event: React.ClipboardEvent<HTMLDivElement>) => {
    event.preventDefault();
    const text = event.clipboardData.getData('text/plain');
    setDescription(text); // Set the editor content to the pasted plain text
  };

  return (
    <>
      <Loader isLoad={loading} />
      <MainContainer>
        <form onSubmit={formik.handleSubmit}>
          <div className="main_loyout">
            <div className="dashboard article-dash">
              <h1 className="mn_hdng">
                {/* Manage Article */}
                Edit Video{" "}
              </h1>
              <Button className="btn btn_primary" onClick={() => navigate(-1)}>
               Go Back
              </Button>
            </div>
            <Card className="cards article-page">
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    id="headlineText"
                    label="Headline Text"
                    multiline
                    type={"text"}
                    name="headlineText"
                    variant="outlined"
                    fullWidth
                    inputProps={{ maxLength: 100 }}
                    placeholder={"Headline Text"}
                    onBlur={formik.handleBlur}
                    value={formik.values.headlineText}
                    onChange={(val) => {
                      if (
                        val.target.value === " " ||
                        val.target.value === "."
                      ) {
                      } else if (isValidInputWithSC(val.target.value)) {
                        formik.handleChange(val);
                      }
                    }}
                    helperText={
                      formik.touched.headlineText && formik.errors.headlineText
                    }
                    rows={6}
                  />
                </Grid>
                <Grid item xs={6}>
                <div>
                  <ReactQuill value={description} onChange={setDescription}
                   modules={{
                    toolbar: [
                      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                      [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                      // [{ 'align': [] }],
                      ['clean']

                    ]
                  }}

                  // onPaste={handlePaste} // Include the onPaste event

                  />
                    
                  </div>
                  {/* <TextField
                    id="description"
                    label=" Please enter your Description"
                    multiline
                    type={"text"}
                    name="description"
                    variant="outlined"
                    fullWidth
                    inputProps={{ maxLength: 1000 }}
                    placeholder={"Description"}
                    onBlur={formik.handleBlur}
                    value={formik.values.description}
                    onChange={(val) => {
                      if (
                        val.target.value === " " ||
                        val.target.value === "."
                      ) {
                      } else if (isValidInputWithSC(val.target.value)) {
                        formik.handleChange(val);
                      }
                    }}
                    helperText={
                      formik.touched.description && formik.errors.description
                    }
                    rows={6}
                  /> */}
                </Grid>
                <Grid item xs={12} lg={6} sm={12}>
                  {videoPreview ? (
                    <div className="image-preview">
                      <div
                        onClick={(e) => {
                          e.stopPropagation();
                          setVideo("");
                          setVideoPreview("");
                        }}
                        style={{ position: "absolute", zIndex: 1111 }}
                        className="close-icon"
                      >
                        <CloseIcon />
                      </div>
                      <div style={{position:"relative",paddingBottom:"56.25%", width:"100%",height:"0"}}>
                        <video style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%", objectFit: "cover" }}
                          src={videoPreview}
                          // width="100%"
                          // height="auto"
                          controls
                        />
                      </div>
                    </div>
                  ) : (
                    <Button
                      variant="contained"
                      component="label"
                      className="custom-input"
                    >
                      Upload File
                      <input type="file" hidden onChange={handleVideoUpload} />
                    </Button>
                  )}
                </Grid>

                <Grid item xs={12} lg={6} sm={12}>
                  {thumbnailPreview ? (
                    <div className="image-preview" style={{aspectRatio:"16/9"}}>
                      <div
                        onClick={() => {
                          setThumbnail("");
                          setThumbnailPreview("");
                        }}
                        className="close-icon"
                      >
                        <CloseIcon />
                      </div>
                      
                        <img src={thumbnailPreview} alt="" />
                     
                    </div>
                  ) : (
                    <Button
                      variant="contained"
                      component="label"
                      className="custom-input"
                    >
                      Upload File
                      <input
                        type="file"
                        hidden
                        onChange={handleThumbnailUpload}
                      />
                    </Button>
                  )}
                </Grid>
              </Grid>
              <Grid container spacing={2} mt={2}>
                <Grid
                  item
                  xs={12}
                  lg={6}
                  sm={6}
                  display="flex"
                  justifyContent={{ xs: "center", lg: "flex-start" }}
                >
                  {articleId ? (
                    <ul className="footer-list">
                      <li>{otherDetails?.uuid || "N/A"}</li>
                      <li>{otherDetails?.author || "N/A"}</li>
                      <li>{otherDetails?.status || "N/A"}</li>
                    </ul>
                  ) : null}
                </Grid>
                <Grid
                  item
                  xs={12}
                  lg={6}
                  sm={6}
                  display="flex"
                  justifyContent={{ xs: "center", lg: "flex-end" }}
                  alignItems="center"
                >
                  {articleId ? (
                    <>
                      <Button
                        type="submit"
                        id="basic-button"
                        variant="contained"
                        className="update-btn"
                        onClick={() => setOpen(true)}
                        sx={{
                          display:
                            otherDetails?.status === "Draft" ||
                            otherDetails?.status === "Received"
                              ? "block"
                              : "none",
                        }}
                      >
                        Discard
                      </Button>
                      <Button
                        type="submit"
                        id="basic-button"
                        variant="contained"
                        onClick={() => setStatus("Disabled")}
                        sx={{
                          display:
                            otherDetails?.status === "Published"
                              ? "block"
                              : "none",
                        }}
                      >
                        Disable
                      </Button>
                      <Button
                        id="basic-button"
                        variant="contained"
                        className="update-btn"
                        onClick={() => {
                          setStatus("Draft");
                          submitVideos("Draft");
                        }}
                        sx={{
                          display:
                            otherDetails?.status === "Draft" ||
                            otherDetails?.status === "Received"
                              ? "block"
                              : "none",
                        }}
                      >
                        Save
                      </Button>
                      <Button
                        type="submit"
                        id="basic-button"
                        variant="contained"
                        className="update-btn"
                        onClick={() => setStatus(otherDetails?.status || "")}
                        sx={{
                          display:
                            otherDetails?.status === "Published" ||
                            otherDetails?.status === "Disabled"
                              ? "block"
                              : "none",
                        }}
                      >
                        Update
                      </Button>
                      <Button
                        type="submit"
                        id="basic-button"
                        variant="contained"
                        onClick={() => setStatus("Published")}
                        sx={{
                          display:
                            otherDetails?.status === "Draft" ||
                            otherDetails?.status === "Disabled" ||
                            otherDetails?.status === "Received"
                              ? "block"
                              : "none",
                        }}
                      >
                        Publish
                      </Button>
                    </>
                  ) : (
                    <>
                      <Button
                        id="basic-button"
                        variant="contained"
                        className="update-btn"
                        onClick={() => {
                          setStatus("Draft");
                          submitVideos("Draft");
                        }}
                      >
                        Save
                      </Button>
                      <Button
                        type="submit"
                        id="basic-button"
                        variant="contained"
                        onClick={() => setStatus("Published")}
                      >
                        Publish
                      </Button>
                    </>
                  )}
                </Grid>
              </Grid>
            </Card>
          </div>
        </form>
      </MainContainer>
      <WarnModal
        open={open}
        setOpen={setOpen}
        handleDelete={discardArticle}
        name="Video"
      />
    </>
  );
};

export default VideoForm;
