import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Box,
  Button,
  FormControl,
  Grid,
  LinearProgress,
  Paper,
} from "@mui/material";
import PeopleIcon from "@mui/icons-material/People";
import InventoryIcon from "@mui/icons-material/Inventory";
import PaidIcon from "@mui/icons-material/Paid";
import MainContainer from "../../layout/MainContainer";
import { GetDashboard } from "../../services/home";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#204e33" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

type Dashboard = {
  postCountPrevious: number;
  postCount: number;
  userCountPrevious: number;
  userCount: number;
  subscriptionCount: number;
  subscriptionCountPrevious: number;
};

const DashBoard = () => {
  const [opened, setOpened] = useState<boolean>(false);
  const [filterBy, setFilterBy] = useState("Daily");
  const [dashboardData, setDashboardData] = useState<Dashboard>({
    postCount: 0,
    postCountPrevious: 0,
    subscriptionCount: 0,
    subscriptionCountPrevious: 0,
    userCount: 0,
    userCountPrevious: 0,
  });

  const getDashboard = async () => {
    try {
      const response = (await GetDashboard({ filterBy })) as {
        code: number;
        data: Dashboard;
      };
      if (response?.code === 200) {
        setDashboardData({
          ...dashboardData,
          postCount: response?.data?.postCount || 0,
          postCountPrevious: response?.data?.postCountPrevious || 0,
          userCount: response?.data?.userCount || 0,
          userCountPrevious: response?.data?.userCountPrevious || 0,
          subscriptionCount: response?.data?.subscriptionCount || 0,
          subscriptionCountPrevious:
            response?.data?.subscriptionCountPrevious || 0,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const showNow = () => {
    switch (filterBy) {
      case "Daily":
        return "today";
      case "Weekly":
        return "this week";
      case "Monthly":
        return "this month";
      case "Yearly":
        return "this year";
      default:
        break;
    }
  };

  const showPast = () => {
    switch (filterBy) {
      case "Daily":
        return "Yesterday";
      case "Weekly":
        return "Past week";
      case "Monthly":
        return "Past month";
      case "Yearly":
        return "Past year";
      default:
        break;
    }
  };

  useEffect(() => {
    getDashboard();
  }, [filterBy]);

  return (
    <MainContainer>
      <div className="main_loyout" onClick={() => setOpened(false)}>
        <div className="dashboard">
          <h1 className="mn_hdng">Dashboard</h1>
        </div>

        <Grid
          container
          spacing={2}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Grid item></Grid>
          <Grid item>
            <div className="parent-box">
              <Button
                onClick={(e: any) => {
                  e.stopPropagation();
                  setOpened(true);
                }}
                id="basic-button"
                variant="contained"
              >
                Filter stats
              </Button>
              {opened ? (
                <div
                  onClick={(e) => e.stopPropagation()}
                  className="filter-options"
                >
                  <h3>Filter Options</h3>
                  <div className="filter-div">
                    <FormControl fullWidth>
                      <label>Period</label>
                      <select
                        onChange={(val) => {
                          setFilterBy(val.target.value);
                          setOpened(false);
                        }}
                        value={filterBy}
                        name="cars"
                        id="cars"
                      >
                        <option value="Daily">By Today</option>
                        <option value="Weekly">Last 7 days</option>
                        <option value="Monthly">Last 30 days</option>
                        <option value="Yearly">Last year</option>
                      </select>
                    </FormControl>
                  </div>
                </div>
              ) : null}
            </div>
          </Grid>
        </Grid>

        <Grid container spacing={2} className="dashGrid">
          <Grid item lg={4} md={4} sm={6} xs={12}>
            <Item className="cards dashGrid_item ">
              <Box className="upr_items">
                <PeopleIcon className="svg_icn" />
                <div>
                  <h3>Total Users</h3>
                  <h4 className="mn_hdng">{dashboardData?.userCount || 0}</h4>
                  <p>New users {showNow()}</p>
                </div>
              </Box>
              <Box display={"flex"} justifyContent={"space-between"}>
                <p>{showPast()}</p>
                <p>{dashboardData?.userCountPrevious || 0}</p>
              </Box>
              <LinearProgress
                style={{ width: "100%" }}
                variant="determinate"
                value={
                 40
                }
              />
            </Item>
          </Grid>
          <Grid item lg={4} md={4} sm={6} xs={12}>
            <Item className="cards dashGrid_item ">
              <Box className="upr_items">
                <InventoryIcon className="svg_icn" />
                <div>
                  <h3>Total posts</h3>
                  <h4 className="mn_hdng">{dashboardData?.postCount || 0}</h4>
                  <p>New Posts {showNow()}</p>
                </div>
              </Box>
              <Box display={"flex"} justifyContent={"space-between"}>
                <p>{showPast()}</p>
                <p>{dashboardData?.postCountPrevious || 0}</p>
              </Box>
              <LinearProgress
                style={{ width: "100%" }}
                variant="determinate"
                value={
                  60
                }
              />
            </Item>
          </Grid>

          <Grid item lg={4} md={4} sm={6} xs={12}>
            <Item className="cards dashGrid_item ">
              <Box className="upr_items">
                <PaidIcon className="svg_icn" />
                <div>
                  <h3>Total subscriptions</h3>
                  <h4 className="mn_hdng">
                    {dashboardData?.subscriptionCount || 0}
                  </h4>
                  <p>New subscriptions {showNow()}</p>
                </div>
              </Box>
              <Box display={"flex"} justifyContent={"space-between"}>
                <p>{showPast()}</p>
                <p>{dashboardData?.subscriptionCountPrevious || 0}</p>
              </Box>
              <LinearProgress
                style={{ width: "100%" }}
                variant="determinate"
                value={
                30
                }
              />
            </Item>
          </Grid>
        </Grid>
      </div>
    </MainContainer>
  );
};

export default DashBoard;
