import { useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { STORAGE_KEYS, getFromStorage } from "./constants";
import Pages from "./pages";

const Routing = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const token = getFromStorage(STORAGE_KEYS.token);
    if (token && window.location.pathname === "/") {
      navigate("/dashboard", { replace: true });
    } else if (!token && window?.location?.pathname === "/") {
      navigate("/", { replace: true });
    } else if (token) {
      navigate(window?.location?.pathname);
    } else {
      navigate("/", { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Routes>
      <Route path="/" element={<Pages.LoginPage />} />
      <Route path="/dashboard" element={<Pages.DashBoardPage />} />
      <Route path="/manage-users" element={<Pages.UsersPage />} />
      <Route path="/manage-users/:id" element={<Pages.UserDetails />} />
      <Route
        path="/manage-articles/:categoryId/add"
        element={<Pages.AddArticlePage />}
      />
      <Route
        path="/manage-articles/:categoryId/edit/:articleId"
        element={<Pages.AddArticlePage />}
      />
      <Route
        path="/manage-videos/:categoryId/add"
        element={<Pages.AddVideoPage />}
      />
      <Route path="/manage-videos/add" element={<Pages.AddVideoPage />} />
      <Route
        path="/manage-videos/:categoryId/edit/:articleId"
        element={<Pages.AddVideoPage />}
      />
      <Route
        path="/manage-articles/:id"
        element={<Pages.ArticleDetailPage />}
      />
      <Route path="/manage-gst/:type" element={<Pages.GSTPage />} />
      <Route path="/manage-customs/:type" element={<Pages.CustomPage />} />
      <Route path="/manage-gst-video" element={<Pages.AddVideoPage />} />
      <Route
        path="/article-details/:articleId"
        element={<Pages.ArticleDetailPage />}
      />
      <Route path="/reported" element={<Pages.ReportPage />} />
      <Route path="/transactions" element={<Pages.PaymentPage />} />
      <Route path="/comments" element={<Pages.CommentsPage />} />
      <Route path="/comments/:commentId" element={<Pages.CommentsDetail />} />
      <Route path="/forum-posts" element={<Pages.ForumPostsPage />} />
      <Route path="/forum-posts/:articleId" element={<Pages.ForumPostsDetails />} />
    </Routes>
  );
};

export default Routing;
