import { useEffect } from "react";
import "./App.scss";
import Routing from "./Routes";
import {requestPermission,getMessage} from "./utils/firebase"
function App() {
  if ("serviceWorker" in navigator) {
    window.addEventListener("load", function () {
      navigator.serviceWorker
        .register("/firebase-messaging-sw.js")
        .then(function (registration) {
          console.log("ServiceWorker registration successful with scope: ", registration.scope);
        })
        .catch(function (err) {
          console.error("ServiceWorker registration failed: ", err);
        });
    });
  }
  useEffect(()=>{
    const FCMTOKEN = localStorage.getItem("fcmToken")
    const token = localStorage.getItem("@secure.s.TOKEN")
    // console.log(token,"token")
    if(token){
      if(!FCMTOKEN){
        requestPermission()
      }
    }
 
    getMessage()
  },[])

  return <Routing />;
}

export default App;